import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-ouvertureev',
  templateUrl: './ouvertureev.component.html',
  styleUrls: ['./ouvertureev.component.scss'],
})
export class OuvertureevComponent implements OnInit {
  @Input() programmation:any;
  @Input() programmeActif:any;


  isOuverture = true;
  constructor(public utils : UtilsService) { }
  ngOnInit() {
  //est ce une vrai ouverture ou un entretien solenoides
  // if (this.programmation.unite == 's') {
  // if(this.programmation.derniereOuvertureDuree > 60) {
  // this.isOuverture = true;
  // }
  // }else if (this.programmation.unite == 'min') {
  // if(this.programmation.derniereOuvertureDuree > 1) {
  // this.isOuverture = true;
  // }
  // }else if (this.programmation.unite == 'h') {
  // this.isOuverture = true;
  // }

  // if (this.programmeActif) {

  // }


  }
}
