import {Component, Input, OnInit} from '@angular/core';
import {DiagFicheSite} from '../../interfaces/Diag/DiagFicheSite';

@Component({
    selector: 'app-fiche-site-modal',
    templateUrl: './fiche-site-modal.page.html',
    styleUrls: ['./fiche-site-modal.page.scss'],
})
export class FicheSiteModalPage implements OnInit {
    @Input() projectId: number | string;
    @Input() ficheSite: DiagFicheSite;

    constructor(
    ) {
    }

    ngOnInit() {
    }
}
