import {Component, AfterViewInit, ViewChild, ElementRef, Input} from '@angular/core';
import {decode} from 'blurhash';

@Component({
    selector: 'app-blurhash',
    templateUrl: './blurhash.component.html',
    styleUrls: ['./blurhash.component.scss'],
})
export class BlurhashComponent implements AfterViewInit {

    private blurHashValue: string;

    @Input()
    get blurHash(): string {
        return this.blurHashValue;
    }

    set blurHash(value: string) {
        this.blurHashValue = value;
        this.decodeBlurHash();
    }

    @Input()
    public imageSrc: string;

    @ViewChild('canvas', {static: true})
    private canvas: ElementRef<HTMLCanvasElement>;

    public canvasWidth = 32;
    public canvasHeight = 32;
    public imageLoaded = false;

    public ngAfterViewInit(): void {
        this.decodeBlurHash();
    }

    private decodeBlurHash() {
        if (this.canvas && this.blurHash) {
            const context = this.canvas.nativeElement.getContext('2d');
            const imageData = context.createImageData(this.canvasWidth, this.canvasHeight);
            const pixels = decode(this.blurHash, this.canvasWidth, this.canvasHeight);
            imageData.data.set(pixels);
            context.putImageData(imageData, 0, 0);
        }
    }
}
