import {KeyValue} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';
import {EVService} from '../services/ev.service';
import {LoadingService} from '../services/loading.service';
import {MixpanelService} from '../services/mixpanel.service';

@Component({
    selector: 'app-detail-bulletin',
    templateUrl: './detail-bulletin.page.html',
    styleUrls: ['./detail-bulletin.page.scss'],
})
export class DetailBulletinPage implements OnInit {
    id_bulletin: any;
    bulletin: any;
    entete: any;
    profileJson;
    photo_url = environment.sujet_photo_url;
    isLoading = true;
    @Input() id_relsp: any;

    constructor(private auth: AuthService, private http: HttpClient, private ls: LoadingService, private MixpanelService: MixpanelService, private modalCtrl: ModalController, private route: ActivatedRoute, private ev: EVService, public sanitizer: DomSanitizer, public translate: TranslateService) {
        this.route.params.subscribe((params) => {

            this.id_relsp = params.id_bulletin;

        });
        //this.MixpanelService.track("Détail bulletin", { 'id_reslsp': this.id_relsp});


    }

    closeModal() {
        this.modalCtrl.dismiss({
            'dismissed': true
        });
    }

    getBulletin(bull) {
        //ON extrait le type du doc depuis name
        let name = "bulletin.pdf";



        // this.getBase64ImageFromUrl(environment.sujet_photo_url + 'Southside_api/display.php?photourl=' +this.photo.url + '&image_token=' + this.profileJson['http://urbasense/image_token'])
        // .then(result => {this.base64 = result;this.original=result})
        // .catch(err => console.error(err));
        this.http.get(this.photo_url + 'Southside_api/getDoc.php?docurl=' + bull.pdf_path + '&namedoc=' + name + '&image_token=' + this.profileJson['http://urbasense/image_token'], {
            responseType: "blob",
            headers: {'Accept': 'application/pdf'}
        })
            .subscribe(blob => {
                console.log(blob);
                saveAs(blob, name);
                return false;
            });


    }


    orderOriginal = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0
    }

    convertToJSON(product: any) {
        return JSON.parse(product);
    }

    prs(jj) {
        return JSON.parse(jj)
    }


    ngOnInit() {

        this.auth.user$.subscribe(
            (profile) => {
                this.profileJson = profile;

            }
        );
        this.ls.loadingPresent(this.translate.instant('chargement_bulletins'));
        this.ev.v7_detail_bulletin(this.id_relsp).subscribe(res => {
            let b: any = res;
            this.bulletin = b.dernier_bulletin.groupes;
            //on doit extraire entete avec bon id_relsp
            this.entete = b.dernier_bulletin;
            this.ls.loadingDismiss();
            this.isLoading = false;
        });
    }

}
