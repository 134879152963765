import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ResponsiveService} from '../../../services/responsive.service';
import {ITab} from '../../../interfaces/ITab';
import {IonTabs} from '@ionic/angular';

@Component({
    selector: 'app-responsive-tab-bar',
    templateUrl: './responsive-tab-bar.component.html',
    styleUrls: ['./responsive-tab-bar.component.scss'],
})
export class ResponsiveTabBarComponent implements OnInit {
    // @ViewChild('ref', {static: false}) ref: IonTabs;
    @Input() tabs: ITab[] = [];
    @Input() refName: string;
    @Input() onIonTabDidChange: () => void = () => {
    }
    selectedTab: string;

    constructor(
        public responsiveService: ResponsiveService,
    ) {
    }

    ngOnInit() {
    }

    /**
     * Handle Ion Tab change event.
     * A callback can be passed to this component
     */
    handleTabDidChange() {
        this.onIonTabDidChange();
    }

    // setCurrentTab() {
    //   this.selectedTab = this.ref.getSelected();
    // }

}
