export enum LeafletEventTriggerEnum {
    CLICK = 'click',
    CONTEXT_MENU = 'contextmenu',
    DRAG_START = 'dragstart',
    DRAG_END = 'dragend',
    LOCATION_FOUND = 'locationfound',
    POPUP_OPEN = 'popupopen',
    MOVE_END = 'moveend',
    ZOOM_END = 'zoomend',
}
