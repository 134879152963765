import {Component, OnInit} from '@angular/core';
import {ActionSheetController, ModalController, NavController, NavParams} from '@ionic/angular';
import {environment} from '../../../../environments/environment';
import {InterventionService} from '../../../services/intervention.service';
import {UserService} from '../../../services/user.service';
import {UserRolesEnum} from '../../../enums/UserRolesEnum';
import {IIntervention} from '../../../interfaces/IIntervention';

type PageMode = 'creer_mission' | 'voir_mission';

@Component({
    selector: 'app-creermission',
    templateUrl: './creermission.page.html',
    styleUrls: ['./creermission.page.scss'],
})
export class CreermissionPage implements OnInit {
    id_mission: string | number;
    interventionsSelectionnees: string[] | number[];
    type_demandeur: UserRolesEnum = UserRolesEnum.INSTALLATEUR;

    readOnlyMission = false;
    typePage: PageMode = 'creer_mission';

    constructor(
        private modalCtrl: ModalController,
        private navParams: NavParams,
        public interventionService: InterventionService,
        public userService: UserService,
    ) {
    }

    ngOnInit() {
        this.interventionsSelectionnees = this.navParams.get('interventionsSelectionnees');

        if (this.navParams.get('mission_id')) {
            this.id_mission = this.navParams.get('mission_id');
            this.type_demandeur = this.navParams.get('type_demandeur');
            this.typePage = 'voir_mission';
        }

        if (Number(this.navParams.get('type_demandeur')) === UserRolesEnum.INTERVENTION_ADMIN) {
            this.type_demandeur = UserRolesEnum.INTERVENTION_ADMIN;
        }
    }

    closeModal() {
        this.modalCtrl.dismiss({
            dismissed: true,
            // list_inter: this.intervDisplay.map(a => a.interv_id)
        });
    }
}
