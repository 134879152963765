import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phoneNumberFilter'
})
export class PhoneNumberFilterPipe implements PipeTransform {

    transform(phoneNumber: string, {prefix = null, separator = ''}): string {
        phoneNumber = phoneNumber.replace(/[\s\.-]/g, '');
        phoneNumber = phoneNumber.match(/\d{1,2}/g).join(separator);

        if (prefix != null) {
            return phoneNumber.replace(/^\d/, prefix);
        }

        return phoneNumber;
    }

}
