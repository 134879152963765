export enum ProjetArchiveIndexEnum {
    BILANS,
    BILANS_OP,
    OTHERS,
    PLANS_IMPLANTATION,
    DIAGNOSTICS,
}

export enum ProjetDocumentIndexEnum {
    URL,
    FILENAME,
    IMAGE_TOKEN,
}
