import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActionSheetController, ModalController, NavController} from '@ionic/angular';
import {environment} from '../../../../environments/environment';

import * as L from 'leaflet';
import * as Leaflet from 'leaflet';
import {LocationEvent} from 'leaflet';

import 'leaflet.locatecontrol/dist/L.Control.Locate.min';
import 'leaflet.markercluster';
import 'leaflet-routing-machine';
import {InterventionStatusEnum} from '../../../enums/InterventionStatusEnum';
import {UserRolesEnum} from '../../../enums/UserRolesEnum';
import {IIntervention} from '../../../interfaces/IIntervention';
import {IInstallateur} from '../../../interfaces/IMission';
import {EVService} from '../../../services/ev.service';
import {UtilsService} from '../../../services/utils.service';
import {InterventionService} from '../../../services/intervention.service';
import {UserService} from '../../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {MissionService} from '../../../services/mission.service';
import {EventService} from '../../../services/event.service';
import {ColorSchemeService} from '../../../services/colorscheme.service';
import {IonicColorKeyEnum} from '../../../enums/IonicColorKeyEnum';
import {ChoixinstallateurPage} from '../../../interventions/choixinstallateur/choixinstallateur.page';
import {LeafletEventTriggerEnum} from '../../../enums/LeafletEventTriggerEnum';
import {IUser} from '../../../interfaces/IUser';
import {AuthService} from '@auth0/auth0-angular';
import {ISujet} from '../../../interfaces/ISujet';
import {ConfirmationdialogComponent} from '../../../interventions/confirmationdialog/confirmationdialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ResponsiveService} from '../../../services/responsive.service';
import {Subscription} from 'rxjs';

export type PageMode = 'creer_mission' | 'voir_mission';

@Component({
    selector: 'app-mission-editor',
    templateUrl: './mission-editor.component.html',
    styleUrls: ['./mission-editor.component.scss'],
})
export class MissionEditorComponent implements OnInit, OnDestroy {
    user: IUser;
    @Input() missionId: string | number;
    @Input() interventionsSelectionnees: number[] | string[];
    @Input() typeDemandeur: UserRolesEnum = UserRolesEnum.INSTALLATEUR;
    typePage: PageMode = 'creer_mission';
    panelStateText: string = 'Masquer la carte'; // Texte initial
    readOnlyMission = false;
    canBeAcceptedOrDeclined = false;

    currentTheme: string;
    classColor = '';
    isClicked = false;

    missionStatut: InterventionStatusEnum;
    intervDisplay: IIntervention[];
    sites: string[] = [];

    startAt: Date = new Date();
    endAt: Date = new Date();
    // Form
    minDate: Date = new Date();
    maxDate: Date = new Date();
    interventionSurUnJour = false;

    // Choix installateur
    missionCanBeTransfered = false;
    installateur: IInstallateur; // Installateur the mission should be assigned to
    newInstallateur: IInstallateur; // Installateur the mission should be transfered to

    // Leaflet
    map: L.Map;
    routingControl: any;
    markersaajouter = new L.FeatureGroup();
    Mapoptions = {
        layers: [
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            })
        ],
        zoom: 6,
        center: L.latLng(48.801408, 2.130122)
    };
    minisenseCheckerUrl: string = environment.production ?
        'https://arbre.urbasense.eu/api_ext/private_uu3qt9CUQrZ4Tswd/device_up.php'
        : 'https://preprodarbre.urbasense.eu/api_ext/private_uu3qt9CUQrZ4Tswd/device_up.php';
    userLocation: Leaflet.LatLng;

    private subscription: Subscription;
    private authSubscription: Subscription;
    private missionSubscription: Subscription;

    constructor(
        private modalCtrl: ModalController,
        private ev: EVService,
        private utils: UtilsService,
        private actionSheetCtrl: ActionSheetController,
        public interventionService: InterventionService,
        public userService: UserService,
        public responsiveService: ResponsiveService,
        private translateService: TranslateService,
        private missionService: MissionService,
        private eventService: EventService,
        private navController: NavController,
        private colorSchemeService: ColorSchemeService,
        private auth: AuthService,
        private dialog: MatDialog,
        private trans: TranslateService,
    ) {
        this.setMinMaxDate();
        this.setTheme();
    }

    ngOnInit() {
        this.authSubscription = this.auth.user$.subscribe(u => {
            this.user = u as IUser;

            console.log('id mission');
            console.log(this.missionId);

            if (this.missionId) {
                this.getDetailMission(this.missionId);
                console.log(this.intervDisplay);
                this.typePage = 'voir_mission';
            }

            if (this.typeDemandeur === UserRolesEnum.INTERVENTION_ADMIN
                && this.userService.hasRole(this.user, UserRolesEnum.INTERVENTION_ADMIN)) {
                this.typeDemandeur = UserRolesEnum.INTERVENTION_ADMIN;
                console.log('ADMIN MODE');
            }

            this.subscription = this.eventService.interventionsAndMissions$.subscribe(eventWasEmitted => {
                if (eventWasEmitted && this.missionId) {
                    console.log('Refreshing mission data');
                    this.getDetailMission(this.missionId, false);
                }
            });
        });
    }

    onAccordionOpened() {

        this.panelStateText = 'Masquer la carte';
    }

    // Méthode appelée lorsque l'accordéon est fermé
    onAccordionClosed() {

        this.panelStateText = 'Afficher la carte';
    }

    setMinMaxDate() {
        const currentYear = new Date().getFullYear();
        this.minDate = new Date();
        this.maxDate = new Date(currentYear + 1, 11, 31);
    }

    setTheme() {
        this.colorSchemeService.load();
        this.currentTheme = this.colorSchemeService.currentActive();
    }

    getDetailMission(missionId: string | number, showMap = true) {
        if (null != this.missionSubscription) {
            this.missionSubscription.unsubscribe();
        }
        ;
        this.missionSubscription = this.ev.getDetailMission(missionId).subscribe(mission => {
                console.log('loading mission for editor');
                console.log(mission);
                this.missionStatut = mission.statut_mission;
                this.intervDisplay = mission.interventions;

                console.log(this.intervDisplay);
                console.log(this.missionStatut);

                this.intervDisplay.forEach(intervention => {
                    intervention.interv_remarque_parsed = this.parseRemarque(intervention);
                });


                this.startAt = new Date(mission.date_debut_mission);
                this.endAt = new Date(mission.date_fin_mission);
                if (mission.date_fin_mission != null) {
                    this.endAt = new Date(mission.date_fin_mission);
                }

                this.installateur = mission.installateur;
                this.sites = mission.sites;
                this.readOnlyMission = true;
                if (showMap) {
                    this.getAndDisplayInterventions();
                }

                // Checking if we can enable mission transfer to another Installateur
                this.missionCanBeTransfered = !this.missionService.isFinished(this.missionStatut)
                    && this.typeDemandeur === UserRolesEnum.INTERVENTION_ADMIN
                    && this.typePage === 'voir_mission'
                ;

                this.canBeAcceptedOrDeclined = this.missionService
                    .canAcceptOrDeclineMissionByRole(mission, this.typeDemandeur);
            }
        );
    }

    public parseRemarque(intervention: IIntervention): object {
        try {
            return {
                type: 'object',
                ...JSON.parse(intervention.intst_rem),
            };
        } catch (err) {
            return {
                type: 'string',
                content: intervention.intst_rem,
            };
        }
    }

    getAndDisplayInterventions() {
        this.markersaajouter.clearLayers();

        // this.initMap();
        const arrayItineraire = [];
        let indexIntervention = 1;
        this.intervDisplay.forEach(value => {
            const lon = value.positionY;
            const lat = value.positionX;
            let icon = './assets/icons/intervention_type/maint.svg';
            try {
                icon = this.interventionService.getTypeIcon(value.interv_type, JSON.parse(value.interv_detail));
                if (value.interv_type === 'Installation') {
                    icon = './assets/icons/intervention_type/install.png';
                }
            } catch (err) {
                console.error(err);
            }
            arrayItineraire.push(Leaflet.latLng(value.positionX, value.positionY));
            if (this.currentTheme === 'light') {
                this.classColor = 'color: black;';
            } else {
                this.classColor = 'color: #59bb78;';
            }
            const marker = Leaflet.marker([lat, lon], {
                icon: new Leaflet.DivIcon({
                    className: 'my-div-icon',
                    html: `<div style="text-align:center;"><img class="my-div-image"
                     src="` + icon + `"/><div class="my-div-span" style="width:24px;text-align:center;font-size: 2em;
                     font-weight: bolder;` + this.classColor + `">` + indexIntervention + `</div></div>`
                })
            });
            indexIntervention = indexIntervention + 1;
            this.markersaajouter.addLayer(marker);
        });
        if (this.routingControl != null) {
            this.map.removeControl(this.routingControl);
            this.routingControl = null;
        }

        this.routingControl = Leaflet.Routing.control({
            router: Leaflet.Routing.osrmv1({
                serviceUrl: `https://router.project-osrm.org/route/v1/`,
                language: this.translateService.currentLang,
            }),
            showAlternatives: true,
            show: false,
            routeWhileDragging: true,
            waypoints: arrayItineraire,
            plan: Leaflet.Routing.plan(arrayItineraire, {
                createMarker: () => {
                    return null;
                }
            }),
            addWaypoints: false,

        }).addTo(this.map);
        this.markersaajouter.addTo(this.map);
        this.map.fitBounds(this.markersaajouter.getBounds());
    }

    onMapReady(map: L.Map) {
        this.map = map;
        window.dispatchEvent(new Event('resize'));

        if (this.interventionsSelectionnees) {
            this.ev.getInterventionsById(this.interventionsSelectionnees).subscribe(res => {
                this.intervDisplay = res;
                this.getAndDisplayInterventions();
            });
        }


        this.map.locate({
            setView: false, // Ne centre pas la carte sur la position de l'utilisateur
            enableHighAccuracy: true // Utilise la géolocalisation de haute précision si disponible
        });

        this.map.on(LeafletEventTriggerEnum.LOCATION_FOUND,
            (event: LocationEvent) => {
                console.log('LocationEvent');
                if (this.userLocation == null) {
                    console.log('LocationEvent ', event.latlng);
                    this.userLocation = event.latlng;
                }
            }
        );
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.intervDisplay, event.previousIndex, event.currentIndex);
        this.ev.updatePositionInterventions(this.intervDisplay).subscribe(res => {

        });
        this.getAndDisplayInterventions();
    }

    getDetailInter(jsonList) {
        if (jsonList) {
            return JSON.parse(jsonList);
        } else {
            return [];
        }
    }

    supprimerIntervention = async (indexInter: number, idIntervention: string | number) => {
        let str = '';
        if (this.intervDisplay.length === 1) {
            str = `.Etant donné que la mission n'est composée que d'une intervention, la mission sera supprimée.`
        }

        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Etes-vous sur de vouloir retirer l\'intervention de cette mission ' + str + '?',
            buttons: [
                {
                    text: 'Oui',
                    role: 'confirm',
                },
                {
                    text: 'Annuler',
                    role: 'cancel',
                },
            ],
        });

        actionSheet.present();

        const {role} = await actionSheet.onWillDismiss();

        if (role === 'confirm') {
            // delete this.intervDisplay[indexInter];
            this.intervDisplay.splice(indexInter, 1);
            // Traitement ici si intervention deja en base assignée à une mission
            if (this.typePage === 'voir_mission') {
                this.ev.deleteInterventionMission(idIntervention).subscribe(res => {
                    console.log('deleteInterventionMission', res);
                });
            }
            this.getAndDisplayInterventions();
        }

        return role === 'confirm';
    }

    debuterMaintenance(sujet: ISujet, idSujet: number | string, idInterv: number | string) {
        this.ev.v6_sujet(idSujet).subscribe(
            res => {
                // Version page
                this.navController.navigateForward(`/maintenance-form/${idSujet}/${idInterv}`);
                // Version modal
                // this.display.presentModalSujetMaintenanceForm(res, idSujet, idInterv);
            }
        );
    }

    debuterInstallation(sujet: ISujet, idSujet: number | string, idInterv: number | string) {
                // Version page
                this.navController.navigateForward(`/installation-form/${idSujet}/${idInterv}`);
    }

    debuterDiagnostiqueInstallation(idInterv: number | string) {
        this.navController.navigateForward(`/installation-diag-form/${idInterv}`);
    }

    proposerMission() {
        const objMission = {
            id_mission: this.missionId,
        };
        this.isClicked = true;
        this.ev.proposerMission(objMission).subscribe(res => {
            const objRes: any = res;
            this.missionStatut = objRes.statut_mission;
            this.readOnlyMission = true;
            this.intervDisplay = objRes.interventions;
            // Parsing Details to more easily access details
            this.startAt = new Date(objRes.date_debut_mission);
            if (objRes.date_fin_mission != null) {
                this.endAt = new Date(objRes.date_fin_mission);
            }
            this.installateur = objRes.installateur;
            this.getAndDisplayInterventions();
            this.utils.presentToast('La mission va être proposée à l\'installateur', 2000, IonicColorKeyEnum.SUCCESS);
            this.isClicked = false;
            this.closePageAndRefresh();
        });
    }

    async openChoixInstallateur() {
        const modal = await this.modalCtrl.create({
            component: ChoixinstallateurPage,
            cssClass: 'my-custom-class',
            componentProps: {}
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
        if (data.installateur) {
            this.installateur = data.installateur;
        }
    }

    async openChoixNouvelInstallateur() {
        const modal = await this.modalCtrl.create({
            component: ChoixinstallateurPage,
            cssClass: 'my-custom-class',
            componentProps: {}
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
        if (data.installateur) {
            this.newInstallateur = data.installateur;
        }
    }

    async transferMission() {
        await this.confirmAndDo(
            'Etes-vous sur(e) de vouloir transférer cette mission à ' + this.newInstallateur.Nom + ' ' + this.newInstallateur.prenom + ' ?',
            () => {
                this.ev.get_transfer_mission_to_new_installateur(this.missionId, this.newInstallateur.ref_joom)
                    .subscribe(res => {
                        this.closePageAndRefresh();
                    });
            });
    }


    async confirmAndDo(message, action: () => void) {
        const CONFIRM = 'confirm';
        const CANCEL = 'cancel';
        const actionSheet = await this.actionSheetCtrl.create({
            header: message,
            buttons: [
                {
                    text: 'Oui',
                    role: CONFIRM,
                },
                {
                    text: 'Annuler',
                    role: CANCEL,
                },
            ],
        });

        // Showing the actionsheet
        actionSheet.present();
        const {role} = await actionSheet.onWillDismiss();
        if (role === CONFIRM) {
            // Launch action
            action();
        }
    }

    async valideFinIntervention(intervention) {
        await this.confirmAndDo(
            'Etes-vous sur(e) de vouloir valider la fin de l\'intervention ?',
            () => {
                this.validerInterventionFin(intervention);
                this.closePageAndRefresh();
            });
    }

    validerInterventionFin(intervention) {
        const objIntervention = this.generateInterventionMissionRelationObj(intervention);
        this.ev.validerFinIntervention(objIntervention).subscribe(res => {
            this.eventService.emitInterventionAndMissionRefreshEvent();
        });
    }

    async invalideFinIntervention(intervention) {
        await this.confirmAndDo(
            'Etes-vous sur(e) de vouloir signaler cette intervention comme non réalisée ?',
            () => {
                this.invaliderInterventionFin(intervention);
            });
    }


    invaliderInterventionFin(intervention: IIntervention) {
        this.ev.invaliderFinIntervention(
            this.generateInterventionMissionRelationObj(intervention)
        ).subscribe(res => {
            this.eventService.emitInterventionAndMissionRefreshEvent();
        });
    }

    async closeModal() {
        if (await this.modalCtrl.getTop()) {
            this.modalCtrl.dismiss({
                dismissed: true,
                list_inter: this.intervDisplay.map(a => a.interv_id)
            });
        } else {
            this.navController.back();
        }
    }

    private closePageAndRefresh() {
        this.eventService.emitInterventionAndMissionRefreshEvent();
        this.closeModal();
    }

    public unselectInstallateur() {
        this.installateur = null;
        this.newInstallateur = null;
    }

    closeModalAndGoTo(url: string) {
        this.navController.navigateForward(url);
        this.closeModal();
    }

    openGoogleMapsRoute() {
        let googleMapsUrl = 'https://www.google.com/maps/dir/';


        if (this.userLocation) {
            googleMapsUrl += `${this.userLocation.lat},${this.userLocation.lng}/`;
        }


        this.intervDisplay.forEach(value => {
            console.log('intervdisplay', value);
            if (value.intst_statut && value.intst_statut === 'INTERV_STATUT_ACCEPTEE') {
                googleMapsUrl += `${value.positionX},${value.positionY}/`;
            }

        });

        window.open(googleMapsUrl, '_blank');
    }

    async valideConformiteIntervention(intervention: IIntervention) {
        await this.confirmAndDo(
            'Etes-vous sur(e) de vouloir déclarer cette intervention comme réalisée et conforme ?',
            () => {
                this.ev.validerConformiteIntervention(
                    this.generateInterventionMissionRelationObj(intervention)
                ).subscribe(res => {
                    this.eventService.emitInterventionAndMissionRefreshEvent();
                    // this.closePageAndRefresh();
                });
            });
    }

    async invalideConformiteIntervention(intervention: IIntervention) {
        await this.confirmAndDo(
            'Etes-vous sur(e) de vouloir déclarer cette intervention comme non conforme ?',
            () => {
                this.ev.invaliderConformiteIntervention(
                    this.generateInterventionMissionRelationObj(intervention)
                ).subscribe(res => {
                    this.eventService.emitInterventionAndMissionRefreshEvent();
                    // this.closePageAndRefresh();
                });
            });
    }

    annulerIntervention(intervention: IIntervention) {
        this.ev.annulerIntervention(
            this.generateInterventionMissionRelationObj(intervention)
        ).subscribe(res => {
            this.eventService.emitInterventionAndMissionRefreshEvent();
        });
    }

    reproposerIntervention(intervention: IIntervention) {
        this.ev.reproposerIntervention(
            this.generateInterventionMissionRelationObj(intervention)
        ).subscribe(res => {
            this.eventService.emitInterventionAndMissionRefreshEvent();
        });
    }

    private generateInterventionMissionRelationObj(intervention: IIntervention): {
        interv_id: number | string,
        remarque_installateur: string,
        id_mission: number | string,
    } {
        return {
            interv_id: intervention.interv_id,
            remarque_installateur: intervention.remarque_installateur,
            id_mission: this.missionId
        };
    }


    // TODO Should be in MissionDateSelection component
    checkboxClick(e: any): void {
        if (e.detail.checked) {
            this.interventionSurUnJour = true;
            this.endAt = this.startAt;
        } else {
            this.interventionSurUnJour = false;
        }
    }

    validerMission(): void {
        const mission = {
            interventions: this.intervDisplay,
            installateur: this.installateur.ref_joom,
            date_debut: this.startAt.toISOString(),
            date_fin: this.endAt.toISOString(),
        };

        this.ev.validerMission(mission).subscribe(res => {
            if (res != false) {
                console.log(res);
                console.log('Mission created');
                this.utils.presentToast('Mission créée', 2000, IonicColorKeyEnum.SUCCESS);
                this.typePage = 'voir_mission';
                this.missionId = res as number;
                this.typeDemandeur = this.userService.roles.INTERVENTION_ADMIN;
                this.getDetailMission(this.missionId);

                this.eventService.emitInterventionAndMissionRefreshEvent();
            }
        });
    }

    accepterMission() {
        const dialogRef = this.dialog.open(ConfirmationdialogComponent, {
            data: {
                message: this.trans.instant('mission_accept_confirm'),
                buttonText: {
                    ok: this.trans.instant('confirm_yes'),
                    cancel: this.trans.instant('confirm_no')
                }
            }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                const objMission = {id_mission: this.missionId};
                this.ev.accepterMission(objMission).subscribe(res => {
                    this.eventService.emitInterventionAndMissionRefreshEvent();
                });
            }
        });
    }

    declineMission() {
        const dialogRef = this.dialog.open(ConfirmationdialogComponent, {
            data: {
                message: this.trans.instant('mission_decline_confirm'),
                buttonText: {
                    ok: this.trans.instant('confirm_yes'),
                    cancel: this.trans.instant('confirm_no')
                }
            }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                const objMission = {id_mission: this.missionId};
                this.ev.declinerMission(objMission).subscribe(res => {
                    this.eventService.emitInterventionAndMissionRefreshEvent();
                });
            }
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
        this.authSubscription?.unsubscribe();
    }
}
