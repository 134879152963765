import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    selector: 'confirmationdialog',
    templateUrl: 'confirmationdialog.component.html',
})
export class ConfirmationdialogComponent {
    message: string = "Are you sure?"
    confirmButtonText = "Yes"
    cancelButtonText = "Cancel"
    datePeriod = false;
    dateDebut = new Date();
    dateFin = new Date();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<ConfirmationdialogComponent>) {
        if (data) {
            this.message = data.message || this.message;
            if (data.buttonText) {
                this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
                this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
            }
            if (data.datePeriod) {
                this.datePeriod = data.datePeriod || this.datePeriod;
            }

        }
    }

    onConfirmClick(): void {
        if (!this.datePeriod) {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close({start: this.dateDebut, end: this.dateFin});
        }

    }

    close() {
        this.dialogRef.close(false);
    }

}
