import {Component, Input, OnInit} from '@angular/core';
import {IIntervention} from '../../../interfaces/IIntervention';
import {DetailinterventionPage} from '../../../interventions/detailintervention/detailintervention.page';
import {ModalController} from '@ionic/angular';
import {DisplayService} from "../../../services/display.service";

@Component({
    selector: 'app-intervention-raw-list',
    templateUrl: './intervention-raw-list.component.html',
    styleUrls: ['./intervention-raw-list.component.scss'],
})
export class InterventionRawListComponent {
    @Input() interventions: IIntervention[] = [];

    constructor(
        private modalController: ModalController,
        public display: DisplayService,
    ) {
    }
}
