import {Pipe, PipeTransform} from '@angular/core';
import {IIntervention} from './interfaces/IIntervention';
import {TrueInterventionTypeEnum} from './enums/InterventionTypeEnum';
import {InterventionService} from './services/intervention.service';

@Pipe({
    name: 'filtreinterventionType'
})
export class FiltreinterventionTypePipe implements PipeTransform {
    constructor(
        private interventionService: InterventionService,
    ) {
    }

    transform(items: IIntervention[], filters: TrueInterventionTypeEnum[]): IIntervention[] {
        if (!items || !filters) {
            return items;
        }

        return items.filter(intervention => {
            return filters.includes(this.interventionService.getTrueType(intervention));
        });
    }
}
