import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IInterventionHeader} from '../../../interfaces/IInterventionHeader';

@Component({
    selector: 'app-maintenance-confirm-dialog',
    templateUrl: './maintenance-confirm-dialog.component.html',
    styleUrls: ['./maintenance-confirm-dialog.component.scss'],
})
export class MaintenanceConfirmDialogComponent  {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {intervention: IInterventionHeader},
        public dialogRef: MatDialogRef<MaintenanceConfirmDialogComponent>)
    {
    }

    closeDialogWithChoice(choice) {
        this.dialogRef.close(choice);
    }

}
