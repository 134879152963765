import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-calltoaction',
  templateUrl: './calltoaction.component.html',
  styleUrls: ['./calltoaction.component.scss'],
})
export class CalltoactionComponent implements OnInit {
  @Input() type_action: string;
  constructor() {}

  ngOnInit() {
    if ( this.type_action == 'ev') {
   
    }
    if ( this.type_action == 'cs') {
   
    }
  }

}
