import {Component, Input, OnInit} from '@angular/core';
import {IProjet} from '../../../interfaces/IProjet';
import {EVService} from '../../../services/ev.service';
import {UtilsService} from '../../../services/utils.service';

@Component({
    selector: 'app-project-subscription',
    templateUrl: './project-subscription.component.html',
    styleUrls: ['./project-subscription.component.scss'],
})
export class ProjectSubscriptionComponent implements OnInit {
    @Input() project: IProjet;

    sujets: any;
    sujetsDetailsCount = {
        underContract: 0,
        notUnderContract: 0,
    };

    constructor(
        private ev: EVService,
        public utils: UtilsService,
    ) {
    }

    ngOnInit() {
        this.ev.v6_liste_sujets_projet(Number(this.project.id_sit))
            .subscribe(sujets => {
                this.sujets = sujets;
                const underContracts = this.deduceCountSujetUnderContract(this.sujets);
                this.sujetsDetailsCount = {
                    underContract: underContracts,
                    notUnderContract: this.sujets.length - underContracts,
                };
            });
    }

    deduceCountSujetUnderContract(sujets: any[]): number {
        return sujets.filter(sujet => sujet.sujet_sous_contrat == 1).length;
    }

}
