import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-affichage-indicateurs',
  templateUrl: './affichage-indicateurs.component.html',
  styleUrls: ['./affichage-indicateurs.component.scss'],
})
export class AffichageIndicateursComponent implements OnInit {

  constructor() { }
  @Input() data: any;
  @Input() modeAffichage: any;
  ngOnInit() {
    console.log('modeAffichage',this.modeAffichage);
  }

}
