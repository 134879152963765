import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-collapsable',
    templateUrl: './collapsable.component.html',
    styleUrls: ['./collapsable.component.scss'],
})
export class CollapsableComponent implements OnInit, AfterViewInit {
    @Input() title = 'Show';
    isOpened = false;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // const height = element.offsetHeight;
        // console.log('Element height:', height);
    }

    toggleCollapsable() {
        this.isOpened = !this.isOpened;
    }

}
