import {Pipe, PipeTransform} from '@angular/core';
import {InterventionStatusEnum} from './enums/InterventionStatusEnum';
import {IIntervention} from './interfaces/IIntervention';

@Pipe({
    name: 'filtreintervention'
})
export class FiltreinterventionPipe implements PipeTransform {

    transform(items: IIntervention[], filters: InterventionStatusEnum[]): IIntervention[] {
        if (!items || !filters) {
            return items;
        }

        return items.filter(intervention => filters.includes(intervention.status?.intst_statut));
    }

}
