/**
 * Enumerate every Intervention Status cases
 * @author Maxime Montagne
 */
export enum InterventionStatusEnum {
    // Status ouvert
    UNKNOWN = 'INTERV_STATUT_UNKNOWN',
    DEMANDEE = 'INTERV_STATUT_DEMANDE',
    // Intervention jugée injustifiée
    ANNULE = 'INTERV_STATUT_ANNULE', // Should not exist
    ANNULEE = 'INTERV_STATUT_ANNULEE',
    // Intervention jugée justifiée, prête à être proposée
    AFFECTEE = 'INTERV_STATUT_AFFECTE',
    // Intervention proposée à un installateur
    PROPOSEE = 'INTERV_STATUT_AFFECTE_PROPOSE',
    ACCEPTEE = 'INTERV_STATUT_ACCEPTEE',
    REFUSEE = 'INTERV_STATUT_REFUSEE',
    EN_COURS = 'INTERV_STATUT_EN_COURS', // TODO For Mission only ?
    // Réalisé ou non
    REALISEE = 'INTERV_STATUT_REALISEE',
    NON_REALISEE = 'INTERV_STATUT_NON_REALISEE',
    // Après vérification
    CONFORME = 'INTERV_STATUT_VERIFIEE_CONFORME',
    NON_CONFORME = 'INTERV_STATUT_VERIFIEE_NON_CONFORME',
}
