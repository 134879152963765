import {Component, Input, OnInit} from '@angular/core';
import {IProjet} from '../../../interfaces/IProjet';
import {EVService} from '../../../services/ev.service';

@Component({
    selector: 'app-project-chronology',
    templateUrl: './project-chronology.component.html',
    styleUrls: ['./project-chronology.component.scss'],
})
export class ProjectChronologyComponent implements OnInit {
    // @Input() project: IProjet;
    @Input() projectId: number;
    progressionLevel = 1;
    date_mission: string;
    steps = [
        'project_step_reception_bdc',
        'project_step_reception_checkup',
        'project_step_plan_validated',
        'project_step_installation_planified',
        'project_step_running',
    ];

    constructor(
        private ev: EVService,
    ) {
    }

    ngOnInit() {
        this.ev.checkChronology(this.projectId).subscribe((res:any) => {
            let obj = JSON.parse(res);
            const intValue = +obj.step;
            this.date_mission = ': ' + obj.date_mission;
            this.progressionLevel = Number.isInteger(intValue) ? intValue : 0;
        });
    }
}
