import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IIntervention} from '../../../interfaces/IIntervention';
import {MissionService} from '../../../services/mission.service';
import {InterventionService} from '../../../services/intervention.service';
import {UserRolesEnum} from '../../../enums/UserRolesEnum';
import {TrueInterventionTypeEnum} from "../../../enums/InterventionTypeEnum";

@Component({
    selector: '[intervention-row]',
    templateUrl: './intervention-row.component.html',
    styleUrls: ['./intervention-row.component.scss'],
})
export class InterventionRowComponent implements OnInit {
    @Input() intervention: IIntervention;
    @Input() typeDemandeur: UserRolesEnum = UserRolesEnum.INSTALLATEUR;
    @Input() card = false; // Can Switch between TableRow or Card mode depending on screen size
    @Input() short = false;
    @Input() canDoIntervention = false;
    @Input() canCancelIntervention = false;
    @Input() canSelect = true;
    // @Input() test;

    @Output() selectedChange = new EventEmitter<IIntervention>();
    @Output() showMissionHistoryModalEmitter = new EventEmitter<IIntervention>();
    @Output() showInterventionHistoryModalEmitter = new EventEmitter<IIntervention>();
    // @Output() startInterventionEmitter = new EventEmitter<any>();
    @Output() deleteInterventionEmitter = new EventEmitter<any>();
    linkToInterventionForm: string;

    constructor(
        public interventionService: InterventionService,
        public missionService: MissionService,
    ) {
    }

    ngOnInit() {
        this.linkToInterventionForm = this.deduceInterventionLink();
    }

    deduceInterventionLink() {
        if (this.interventionService.getTrueType(this.intervention) === TrueInterventionTypeEnum.INSTALLATION) {
            if (this.interventionService.canMakeInstallationDiag(this.intervention)) {
                return `/installation-diag-form/${this.intervention.interv_id}`;
            } else {
                return `/installation-form/${this.intervention.id_suj}/${this.intervention.interv_id}`;
            }
        }
        return `/maintenance-form/${this.intervention.id_suj}/${this.intervention.interv_id}`;
    }

    handleSelectGroupMember(): void {
        this.selectedChange.emit(this.intervention);
    }

    handleShowMissionHistoryModal() {
        this.showMissionHistoryModalEmitter.emit(this.intervention);
    }

    handleShowInterventionHistoryModal() {
        this.showInterventionHistoryModalEmitter.emit(this.intervention);
    }

    // handleStartIntervention() {
    //     this.startInterventionEmitter.emit(this.intervention);
    // }

    handleDeleteIntervention() {
        this.deleteInterventionEmitter.emit(this.intervention);
    }
}

