import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {InterventionStatusEnum} from '../../../enums/InterventionStatusEnum';
import {InterventionService} from '../../../services/intervention.service';

@Component({
    selector: 'app-status-badge',
    templateUrl: './status-badge.component.html',
    styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent implements OnInit, OnChanges {
    @Input() status: InterventionStatusEnum;
    @Input() alt = false;

    color = '';
    icon = '';

    constructor(
        private interventionService: InterventionService,
    ) {
    }

    ngOnInit() {
        this.deduceIconAndColor();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.deduceIconAndColor();
    }

    /**
     * Uses InterventionStatusService to determine which color and icon
     * should be displayed based on current Status
     */
    deduceIconAndColor(): void {
        if (Array.isArray(this.status)) {
            if (this.status.length > 0) {
                this.status = this.status[this.status.length - 1];
            } else {
                this.status = this.interventionService.status.UNKNOWN;
            }
        }
        this.color = this.interventionService.getStatusColor(this.status);
        this.icon = this.interventionService.getStatusIcon(this.status);
    }

}
