import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ISujetPhotoForList} from '../../../interfaces/ISujetPhoto';
import {IDroits} from '../../../interfaces/IDroits';

@Component({
    selector: 'app-photo-card',
    templateUrl: './photo-card.component.html',
    styleUrls: ['./photo-card.component.scss'],
})
export class PhotoCardComponent implements OnInit {
    @Input() photo: ISujetPhotoForList;
    @Input() permissions: IDroits;

    @Output() handleClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() handleEdit: EventEmitter<ISujetPhotoForList> = new EventEmitter<ISujetPhotoForList>();
    @Output() handleDelete: EventEmitter<ISujetPhotoForList> = new EventEmitter<ISujetPhotoForList>();

    constructor() {
    }

    ngOnInit() {

    }

    onClick() {
        this.handleClick.emit();
    }

    onEdit() {
        this.handleEdit.emit(this.photo);
    }

    onDelete() {
        this.handleDelete.emit(this.photo);
    }

}
