import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
    selector: 'app-time-detail',
    templateUrl: './time-detail.component.html',
    styleUrls: ['./time-detail.component.scss'],
})
export class TimeDetailComponent implements OnInit, OnChanges {
    @Input() start: Date;
    @Input() end: Date;

    @Input() startString: string;
    @Input() endString: string;

    @Input() format = 'dd/MM/yyyy';

    constructor() {
    }

    ngOnInit() {
        this.convertToDate();
    }

    ngOnChanges(changes) {
        this.convertToDate();
    }

    convertToDate() {
        if (this.startString) {
            try {
                this.start = new Date(this.startString);
            } catch (err) {
                console.error('Couldn\'t convert to Date');
                console.error(err);
            }
        }
        if (this.endString) {
            try {
                this.end = new Date(this.startString);
            } catch (err) {
                console.error('Couldn\'t convert to Date');
                console.error(err);
            }
        }
        this.clearDate();
    }

    clearDate() {
        if (this.start && this.end
            && this.start.getDate() === this.end.getDate()) {
            this.end = null;
        }
    }
}
