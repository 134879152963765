import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {EVService} from '../../../services/ev.service';
import {SujetService} from '../../../services/sujet.service';
import {IUser} from '../../../interfaces/IUser';
import {ISujetPhotoForList} from '../../../interfaces/ISujetPhoto';
import { UtilsService } from '../../../services/utils.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-photos-list',
    templateUrl: './photos-list.component.html',
    styleUrls: ['./photos-list.component.scss'],
})
export class PhotosListComponent implements OnInit {
    @Input() projetId;
    myControl = new FormControl();
    user: IUser;
    photos: ISujetPhotoForList[] = [];

    // Gallery state
    showFlag = false;
    selectedImageIndex = -1;

    // Pagination state
    isLoading = false;
    ITEMS_PER_PAGE = 20;
    offset = 0;
    needToFetch = true;
    filterParams = 'none';
    filteredOptions: Observable<string[]>;
    options: string[] = [];
    filtervalue = "";

    constructor(
        public auth: AuthService,
        private ev: EVService,
        private utils:UtilsService,
        private sujetService: SujetService,
    ) {

    }

    ngOnInit() {
        this.auth.user$.subscribe(
            (user) => {
                this.user = user as IUser;
            }
        );
        this.fetchPhotos();
        this.fetchSujets();

    }


 fetchSujets() {
  this.ev.v6_liste_sujets_projet(Number(this.projetId)).subscribe(sujets => {
    if (Array.isArray(sujets)) {
      for (const sujet of sujets) {
        this.options.push(sujet.nom_suj);
      }
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this.filter(value))
      );
    }
  });

}

    /**
     * Fetching Photos of all projects
     * @param CustomEvent ionInfiniteScroll - present only onScroll event
     */
    fetchPhotos(event: CustomEvent = null): void {

        if (event == null) {
            // Only showing custom loader on first load
            this.isLoading = true;
        }
        this.ev.v6_projet_single_photos(this.projetId, this.offset,this.filterParams).subscribe(photos => {
            const newPhotos = photos.map(photo => {
                return this.sujetService.parsePhotoToPhotoListItem(
                    photo,
                    this.user,
                );
            });
            this.photos.push(...newPhotos);
            this.isLoading = false;

            // @ts-ignore
            event?.target.complete();
            this.offset += this.ITEMS_PER_PAGE;

            // If last data, we kill the event listener
            if (this.isLastFetch(newPhotos.length)) {
                // @ts-ignore IonInfinite event
                event?.target.disabled = true;
            }
        });
    }

    /**
     * Checks if their is no more date to fetch
     */
    isLastFetch(freshDataCount: number): boolean {
        return freshDataCount < this.ITEMS_PER_PAGE;
    }

    closeEventHandler() {
        this.showFlag = false;
        this.selectedImageIndex = -1;
    }

    showLightbox(index) {
        this.selectedImageIndex = index;
        this.showFlag = true;
    }

    optionSelected(event: any) {

      }


    onSearch(e) {
        const searchQuery: string = this.utils.normalizeFrenchLetters(e.option.value);
        if (searchQuery.length > 3) {
            this.photos = [];
            this.offset = 0;
           this.filterParams = searchQuery;
           this.fetchPhotos();
        }
   }

   filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

   clearF() {
       this.photos = [];
       this.filtervalue = '';
       this.offset = 0;
       this.filterParams = 'none';

       this.fetchPhotos();
      // this.fetchSujets();
       return false;
   }
}
