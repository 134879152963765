import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-affichagedendro',
  templateUrl: './affichagedendro.component.html',
  styleUrls: ['./affichagedendro.component.scss'],
})
export class AffichagedendroComponent implements OnInit {

  constructor() { }
  @Input() dendro: any;
  @Input() modeAffichage: any;
  ngOnInit() {
  }

}
