import {Component, Input, OnInit} from '@angular/core';
import {IInstallateur} from '../../../interfaces/IMission';

@Component({
  selector: 'app-installateur-card',
  templateUrl: './installateur-card.component.html',
  styleUrls: ['./installateur-card.component.scss'],
})
export class InstallateurCardComponent implements OnInit {
  @Input() installateur: IInstallateur;

  constructor() { }

  ngOnInit() {}

}
