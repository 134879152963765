import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IGalleryItem} from '../../../interfaces/IGalleryItem';

@Component({
    selector: 'app-gallery-group',
    templateUrl: './gallery-group.component.html',
    styleUrls: ['./gallery-group.component.scss'],
})
export class GalleryGroupComponent implements OnInit {
    @ViewChild('anchor') anchor;
    @Input() title: string;
    @Input() images: IGalleryItem[] = [];
    @Input() short = false;

    showFlag = false;
    selectedImageIndex = -1;

    constructor() {
    }

    ngOnInit() {
        //console.log('images',this.images)
    }

    closeEventHandler() {
        this.showFlag = false;
        this.selectedImageIndex = -1;
    }

    showLightbox(index) {
        this.selectedImageIndex = index;
        this.showFlag = true;
        if (!this.short) {
            this.anchor.scrollIntoView({behavior: 'smooth'});
        }
    }
}
