import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EVService } from '../services/ev.service';
import { MixpanelService } from '../services/mixpanel.service';

@Component({
  selector: 'app-detail-compteur',
  templateUrl: './detail-compteur.page.html',
  styleUrls: ['./detail-compteur.page.scss'],
})
export class DetailCompteurPage implements OnInit {
  @Input() id_compteur: any;
  compteur: any;
  segment = 'infos';
  endTime = "";
  startTime="";
  isToday:boolean;
  calendar = {
    mode: 'month',
    currentDate: new Date(),
    locale: 'fr-FR',
    dateFormatter: {
        formatMonthViewDay: function(date:Date) {
            return date.getDate().toString();
        },
        formatMonthViewDayHeader: function(date:Date) {
            return 'MonMH';
        },
        formatMonthViewTitle: function(date:Date) {
            return 'testMT';
        },
        formatWeekViewDayHeader: function(date:Date) {
            return 'MonWH';
        },
        formatWeekViewTitle: function(date:Date) {
            return 'testWT';
        },
        formatWeekViewHourColumn: function(date:Date) {
            return 'testWH';
        },
        formatDayViewHourColumn: function(date:Date) {
            return 'testDH';
        },
        formatDayViewTitle: function(date:Date) {
            return 'testDT';
        }
    }
};
  eventSource: any;
  viewTitle: any;
  data:any
  constructor(private mxs:MixpanelService,private ev:EVService,private modalCtrl:ModalController,private toastController:ToastController, private translate:TranslateService) { }

  ngOnInit() {
    this.mxs.track('Detail compteur ' + this.id_compteur);
    this.ev.v6_infos_compteur(this.id_compteur).subscribe(data => {

      //On doit trouver les arrosages concurrentiels sur le compteur
      this.compteur =   data;
     this.data = data;

      if (this.data.calendrier[0] == true) {
        this.presentToastWithOptions(this.data.calendrier[1]);
      }


    });
  }


  onTimeSelected(ev) {
    // console.log('Selected time: ' + ev.selectedTime + ', hasEvents: ' +
    //     (ev.events !== undefined && ev.events.length !== 0) + ', disabled: ' + ev.disabled);
}

getDateObj(s) {
  var bits = s.split(/[- :]/);
  var date = new Date(bits[0], bits[1] - 1, bits[2]);
  date.setHours(bits[3], bits[4], 0);
  return date;
}


async presentToastWithOptions(arrayCalendar) {

  let str= "";
  let index = 0;
  let day;
  arrayCalendar.forEach(function (value) {

    for (let key of value.keys()) {
      //str += value[key] + '<br>';
      for (const [k, v] of Object.entries(value[key])) {
        if (index === 0) {
          day = this.translate.instant('Lundi');
        }else if (index === 1) {
          day = this.translate.instant('Mardi');
        }else if (index === 2) {
          day = this.translate.instant('Mercredi');
        }else if (index === 3) {
          day = this.translate.instant('Jeudi');
        }else if (index === 4) {
          day = this.translate.instant('Vendredi');
        }else if (index === 5) {
          day = this.translate.instant('Samedi');
        }else if (index === 6) {
          day = this.translate.instant('Dimanche');
        }
        str += this.translate.instant('Le ') + day + this.translate.instant(' à ') + k + this.translate.instant(' avec les devices suivant')+ ' :<br>' + v + '<br>';
      }
  }

  index = index+1;
  });
  const toast = await this.toastController.create({
    header: this.translate.instant('Attention'),
    message: this.translate.instant('Nous avons remarqué des arrosages concurrents sur ce compteur pouvant entrainer une baisse de débit')+' : <br>' + str,
    position: 'top',
    buttons: [
      {
        text: 'Ok',
        role: 'cancel',
        handler: () => {

        }
      }
    ]
  });
  toast.present();
}



 getOverlappingEvents(eventArray) {
  var result = [];
  var a, b;

  // Sort the event array on start time
  eventArray.sort(function(a, b) {

      return a.startTime - b.startTime;
    });

  // Get overlapping events
  for (var i=0, iLen=eventArray.length - 1; i<iLen; i++) {
    a = eventArray[i];
    b = eventArray[i + 1];

    if ((a.startTime <= b.startTime && a.endTime >= b.startTime) ||
        (a.startTime < b.endTime && a.endTime >= b.endTime) ) {
       result.push([a, b]);
    }
  }
  return result;
}

onCurrentDateChanged(event:Date) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    event.setHours(0, 0, 0, 0);
    this.isToday = today.getTime() === event.getTime();
}

onViewTitleChanged(title) {
  this.viewTitle = title;
}

onEventSelected(event) {
}

changeMode(mode) {
  this.calendar.mode = mode;
}


  closeModal() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
