import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-data-row',
  templateUrl: './data-row.component.html',
  styleUrls: ['./data-row.component.scss'],
})
export class DataRowComponent implements OnInit {
  @Input() label: string;
  @Input() value: string | number;
  @Input() values: string[] | number[];

  @Input() labelPrefix = '';
  @Input() valuePrefix = '';
  @Input() valuesPrefix = '';

  constructor() { }

  ngOnInit() {
    console.log({
      label: this.label,
      value: this.value,
      values: this.values,
    });
  }

}
