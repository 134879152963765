import {Component, Input, OnInit} from '@angular/core';
import {InterventionCounters, InterventionService} from '../../../services/intervention.service';
import {UserRolesEnum} from '../../../enums/UserRolesEnum';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-counter-simple',
    templateUrl: './counter-simple.component.html',
    styleUrls: ['./counter-simple.component.scss'],
})
export class CounterSimpleComponent {
    @Input() key: string;
    @Input() role: UserRolesEnum;

    counters$: Observable<InterventionCounters[]> =
        this.interventionService.counters$;

    constructor(
        private interventionService: InterventionService,
    ) {
    }
}
