import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-counter-list',
    templateUrl: './counter-list.component.html',
    styleUrls: ['./counter-list.component.scss'],
})
export class CounterListComponent implements OnInit {
    @Input() labels: string[];
    @Input() data: object = {};

    ngOnInit() {
        if (this.labels == null) {
            this.labels = Object.keys(this.data);
        }
    }

}
