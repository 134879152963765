import {Pipe, PipeTransform} from '@angular/core';
import {IIntervention} from './interfaces/IIntervention';

@Pipe({
    name: 'filtreinterventionUrgent'
})
export class FiltreinterventionUrgentPipe implements PipeTransform {

    transform(items: IIntervention[], showUrgentOnly: boolean = false): IIntervention[] {
        if (!showUrgentOnly) {
            return items;
        }
        return items.filter(i => i.urgent === true);
    }

}
