import {Component, Input, OnInit} from '@angular/core';
import {ISujet} from '../../interfaces/ISujet';
import {EVService} from '../../services/ev.service';


@Component({
    selector: 'app-sujet-maintenance-modal',
    templateUrl: './sujet-maintenance-modal.page.html',
    styleUrls: ['./sujet-maintenance-modal.page.scss'],
})
export class SujetMaintenanceModalPage implements OnInit {
    @Input() sujet: ISujet;
    @Input() sujetId: string | number;
    @Input() interventionId: string | number;

    constructor(
        private ev: EVService,
    ) {
    }

    ngOnInit() {
        // if (this.sujetId != null) {
        //     this.ev.v6_sujet_photos(this.sujetId).subscribe(res => {
        //     });
        // }
    }

}
