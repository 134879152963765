import {Injectable} from '@angular/core';
import {OfferLevelEnum} from '../enums/OfferLevelEnum';
import {SubscriptionStatusEnum} from '../enums/SubscriptionStatusEnum';
import {IonicColorKeyEnum} from '../enums/IonicColorKeyEnum';
import {IProjet} from '../interfaces/IProjet';

@Injectable({
    providedIn: 'root'
})
export class ProjetService {
    offers: typeof OfferLevelEnum = OfferLevelEnum;
    subscriptionStatusEnum: typeof SubscriptionStatusEnum = SubscriptionStatusEnum;

    constructor() {
    }

    /**
     * Determines if the given site has hight enouht offer
     * to access the required piece of data.
     */
    canAccess(has: OfferLevelEnum, required: OfferLevelEnum): boolean {
        return has >= required;
    }

    getSubStatusColor(status: SubscriptionStatusEnum): IonicColorKeyEnum {
        const palette = {
            [SubscriptionStatusEnum.RUNNING]: IonicColorKeyEnum.SUCCESS,
            [SubscriptionStatusEnum.ABOUT_TO_EXPIRE]: IonicColorKeyEnum.WARNING,
            [SubscriptionStatusEnum.EXPIRED]: IonicColorKeyEnum.DANGER,
            [SubscriptionStatusEnum.UNKNOWN]: IonicColorKeyEnum.LIGHT,
        };
        return palette[status];
    }

    deduceSubscriptionStatus(project: IProjet): SubscriptionStatusEnum {
        if (project.date_fin_suivi == null) {
            return SubscriptionStatusEnum.UNKNOWN;
        }

        const finSuivi = new Date(project.date_fin_suivi);
        const now = new Date();

        const getMonthDiff = (d1: Date, d2: Date) => {
            const d1Y = d1.getFullYear();
            const d2Y = d2.getFullYear();
            const d1M = d1.getMonth();
            const d2M = d2.getMonth();

            return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
        };
        const diff = getMonthDiff(now, finSuivi);

        if (now > finSuivi) {
            return SubscriptionStatusEnum.EXPIRED;
        }

        if (diff <= 2) {
            return SubscriptionStatusEnum.ABOUT_TO_EXPIRE;
        }

        return SubscriptionStatusEnum.RUNNING;
    }
}
