export enum InterventionTypeEnum {
    MAINTENANCE = 'maintenance',
    MAINTENANCE_OLD = 'maint',
    INSTALLATION = 'Installation',
    DEPLACEMENT = 'deplacement',
}

export enum TrueInterventionTypeEnum {
    MAINTENANCE = 'maintenance',
    INSTALLATION = 'Installation',
    DEPLACEMENT = 'deplacement',
    DEPOSE = 'depose',
}
