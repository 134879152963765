import {Injectable} from '@angular/core';
import {IUser} from '../interfaces/IUser';
import {UserService} from './user.service';
import {InterventionStatusEnum} from '../enums/InterventionStatusEnum';
import {IMission} from '../interfaces/IMission';
import {InterventionService} from './intervention.service';
import {UserRolesEnum} from '../enums/UserRolesEnum';

@Injectable({
    providedIn: 'root'
})
export class MissionService {

    constructor(
        private userService: UserService,
        private interventionService: InterventionService,
    ) {
    }

    /**
     * Converts a mission id to a '01234567' format
     */
    formatMissionNumber(id: number): string {
        if (id) {
            const str = id.toString();
            const zeros = '0'.repeat(8 - str.length);
            return `M${zeros}${str}`;
        }else {
            return `MNC`;
        }

    }

    /**
     * Checks if current user is able to accept or decline a Mission.
     * Mission must be proposed and user must have Installateur role.
     */
    canAcceptOrDeclineMission(mission: IMission, user: IUser | any): boolean {
        return mission.statut_mission === this.interventionService.status.PROPOSEE &&
            this.userService.hasRole(user, this.userService.roles.INSTALLATEUR);
    }

    canAcceptOrDeclineMissionByRole(mission: IMission, role: UserRolesEnum): boolean {
        return mission.statut_mission === this.interventionService.status.PROPOSEE &&
            role === this.userService.roles.INSTALLATEUR;
    }

    /**
     * Check if given mission can be deleted based on current status.
     */
    isDeletable(mission: IMission): boolean {
        return ![
            InterventionStatusEnum.ACCEPTEE,
            InterventionStatusEnum.AFFECTEE,
            InterventionStatusEnum.REALISEE,
        ].includes(mission.statut_mission);
    }

    isDeletableByUser(mission: IMission, user: IUser | any ): boolean {
        return this.isDeletable(mission)
            && this.userService.hasRole(user, this.userService.roles.INTERVENTION_ADMIN);
    }

    isDeletableByRole(mission: IMission, role: UserRolesEnum): boolean {
        return this.isDeletable(mission)
            && role === this.userService.roles.INTERVENTION_ADMIN;
    }

    isFinished(missionStatus: InterventionStatusEnum): boolean {
        return [
            InterventionStatusEnum.REALISEE,
            InterventionStatusEnum.NON_REALISEE,
            InterventionStatusEnum.CONFORME,
            InterventionStatusEnum.NON_CONFORME,
            InterventionStatusEnum.ANNULEE,
        ].includes(missionStatus);
    }

}
