import {Component, OnInit} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {ModalController} from '@ionic/angular';
import {EVService} from 'src/app/services/ev.service';
import some from 'lodash/some';
import toLower from 'lodash/toLower';

@Component({
    selector: 'app-choixinstallateur',
    templateUrl: './choixinstallateur.page.html',
    styleUrls: ['./choixinstallateur.page.scss'],
})
export class ChoixinstallateurPage implements OnInit {
    sortedData: any;
    backupData: any;

    constructor(
        private modalCtrl: ModalController,
        private ev: EVService,
    ) {
        this.ev.getInstallateurs().subscribe(res => {
            this.sortedData = res;

            this.sortData({
                active: 'nom',
                direction: 'asc',
            });

            this.backupData = JSON.parse(JSON.stringify(this.sortedData));
            // console.log(this.sortedData);
        });
    }

    ngOnInit() {
    }

    selectInstallateur(installateur) {
        // console.log('dismiss', installateur);
        this.modalCtrl.dismiss({
            dismissed: true,
            installateur,
        });
    }


    sortData(sort: Sort) {
        let data;
        this.ev.getInstallateurs().subscribe(
            res => {
                data = res;
                // console.log({data});
                this.sortedData = data.sort((a, b) => {
                    const isAsc = sort.direction === 'asc';
                    switch (sort.active) {
                        case 'nom':
                            return this.compare(a.Nom, b.Nom, isAsc);
                        case 'prenom':
                            return this.compare(a.prenom, b.prenom, isAsc);
                        case 'organisation':
                            return this.compare(a.organisation, b.organisation, isAsc);
                        default:
                            return 0;
                    }
                });
            }
        );
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }


    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    onSearch(e) {
        // console.log(e.target.value);
        this.sortedData = this.backupData
        .filter(o => some(o, v => toLower(v).indexOf(toLower(e.target.value)) > -1))
            // Filter by nom Asc by default
            .sort((a, b) => {
                return this.compare(a.Nom, b.Nom, true);
            });
    }

    clearF() {
        this.sortedData = this.backupData;
    }

    closeModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

}
