import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMission} from '../../../interfaces/IMission';
import {UserRolesEnum} from "../../../enums/UserRolesEnum";

@Component({
    selector: '[mission-row]',
    templateUrl: './mission-row.component.html',
    styleUrls: ['./mission-row.component.scss'],
})
export class MissionRowComponent implements OnInit {
    @Input() mission: IMission;
    @Input() typeDemandeur: UserRolesEnum = UserRolesEnum.INSTALLATEUR;
    @Input() canAcceptOrDeclineMission = false;
    @Input() canDeleteMission = false;
    @Input() card = false;

    @Output() showMissionModalEmitter = new EventEmitter();
    @Output() acceptMissionEmitter = new EventEmitter();
    @Output() declineMissionEmitter = new EventEmitter();
    @Output() deleteMissionEmitter = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    handleShowMissionModal() {
        this.showMissionModalEmitter.emit();
    }

    handleDeleteMission() {
        this.deleteMissionEmitter.emit();
    }

    handleAcceptMission() {
        this.acceptMissionEmitter.emit();
    }

    handleDeclineMission() {
        this.declineMissionEmitter.emit();
    }

}
