import {Pipe, PipeTransform} from '@angular/core';
import {UserRolesEnum} from './enums/UserRolesEnum';

/**
 * Converts a UserRole code to a translation key
 */
@Pipe({
    name: 'user_role_key'
})
export class UserRoleKeyPipe implements PipeTransform {

    transform(role: UserRolesEnum): string {
        return 'USER_ROLE_' + role;
    }

}
