import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {EVService} from '../services/ev.service';
import {MixpanelService} from '../services/mixpanel.service';

@Component({
    selector: 'app-equipement-detail',
    templateUrl: './equipement-detail.page.html',
    styleUrls: ['./equipement-detail.page.scss'],
})
export class EquipementDetailPage implements OnInit {
    segment = 'infos';
    centrale: any;

    isLoading = true;
    @Input() id_device: any;

    constructor(
        private MixpanelService: MixpanelService,
        public modalCtrl: ModalController,
        private route: ActivatedRoute,
        private ev: EVService
    ) {
    }

    closeModal() {
        this.modalCtrl.dismiss({
            'dismissed': true
        });
    }

    ngOnInit() {
        this.MixpanelService.track("Détail équipement", {"id_device": this.id_device});
        this.ev.v6_device_info(this.id_device).subscribe(res => {
            this.centrale = res;
            this.isLoading = false;
        });

    }

    trackMix(str) {
        this.MixpanelService.track(str, {"id_device": this.id_device});
    }

}
