import {Component, Input, OnInit} from '@angular/core';
import {DocumentService} from '../../../services/document.service';
import {ProjetDocument, ProjetDocumentArray} from '../../../interfaces/ProjetDocuments';

@Component({
    selector: 'app-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnInit {
    @Input() rawDocs: ProjetDocumentArray[];
    @Input() docs: ProjetDocument[] = [];

    constructor(
        private documentService: DocumentService,
    ) {
    }

    ngOnInit(
    ) {
        if (this.rawDocs != null) {
            this.docs = this.documentService.parseDocuments(this.rawDocs);
        }
    }

    downloadFile(url, name, token) {
        this.documentService.downloadFile(url, name, token);
    }

}
