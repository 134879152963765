import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {EVService} from 'src/app/services/ev.service';
import {InterventionStatusEnum} from '../../enums/InterventionStatusEnum';
import {InterventionService} from '../../services/intervention.service';
import {IInterventionDetail} from "../../interfaces/IIntervention";

@Component({
    selector: 'app-detailintervention',
    templateUrl: './detailintervention.page.html',
    styleUrls: ['./detailintervention.page.scss'],
})
export class DetailinterventionPage implements OnInit {
    interv_id;
    detailIntervention;
    detail: IInterventionDetail = {};

    constructor(
        private modalCtrl: ModalController,
        private navParams: NavParams,
        private ev: EVService,
        public interventionService: InterventionService,
    ) {
    }

    ngOnInit() {
        this.interv_id = this.navParams.get('interv_id');
        this.ev.getDetailIntervention(this.interv_id).subscribe(res => {
            this.detailIntervention = res;
            // console.log('detail intervention', res);
            // @ts-ignore
            if (res.interv_detail) {
                this.detail = JSON.parse(this.detailIntervention.interv_detail);
            }
        });
    }

    closeModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

}
