import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-affichage-sport6s',
  templateUrl: './affichage-sport6s.component.html',
  styleUrls: ['./affichage-sport6s.component.scss'],
})
export class AffichageSport6sComponent implements OnInit {
  @Input() indicateurs: any;
  @Input() vegetal: any;
  @Input() withLegend: boolean = false;
  sizeBg = 6;
  sizeLg = 2;

  constructor(public utils:UtilsService) { }

  ngOnInit() {
    if (this.withLegend) {
      this.sizeBg = 12;
      this.sizeLg = 6;
    }
  }

  parseValeur(valeur) {
    return parseInt(valeur);

  }

  handleClickShowModal(id,hj) {}

}
