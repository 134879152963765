import {Component, Input, OnInit} from '@angular/core';
import {InterventionTypeEnum, TrueInterventionTypeEnum} from '../../../enums/InterventionTypeEnum';
import {InterventionService} from '../../../services/intervention.service';
import {IInterventionDetail} from '../../../interfaces/IIntervention';

@Component({
    selector: 'app-intervention-type-badge',
    templateUrl: './intervention-type-badge.component.html',
    styleUrls: ['./intervention-type-badge.component.scss'],
})
export class InterventionTypeBadgeComponent implements OnInit {
    @Input() interventionType: InterventionTypeEnum;
    @Input() interventionDetailJson: string;
    @Input() trueInterventionType: TrueInterventionTypeEnum;

    interventionDetail: IInterventionDetail = {};
    icon: string;

    constructor(
        private interventionService: InterventionService,
    ) {
    }

    ngOnInit() {
        if (this.interventionDetailJson) {
            try {
                this.interventionDetail = JSON.parse(this.interventionDetailJson);
            } catch (err) {
                // can't parse
            }
        }
        if (this.trueInterventionType != null) {
            this.icon = this.interventionService.getTrueTypeIcon(this.trueInterventionType);
        } else {
            this.icon = this.interventionService.getTypeIcon(this.interventionType, this.interventionDetail);
        }
    }

}
