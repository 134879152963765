import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-semainier',
  templateUrl: './semainier.component.html',
  styleUrls: ['./semainier.component.scss'],
})
export class SemainierComponent implements OnInit {
  @Input() data: any;
  @Input() ev_heure_ouverture: any;
  @Input() ev_duree_ouverture: any;

  constructor(public utils:UtilsService) { }

  ngOnInit() {


  }

}
