import {Component, Input, OnInit} from '@angular/core';
import {ProjetService} from '../../../services/projet.service';
import {OfferLevelEnum} from '../../../enums/OfferLevelEnum';

@Component({
  selector: 'app-offre-guard',
  templateUrl: './offre-guard.component.html',
  styleUrls: ['./offre-guard.component.scss'],
})
export class OffreGuardComponent implements OnInit {
  @Input() has: OfferLevelEnum = OfferLevelEnum.RAW_DATA;
  @Input() required: OfferLevelEnum = OfferLevelEnum.INDICATEURS;
  @Input() short = false;

  constructor(
      public projetService: ProjetService,
  ) { }

  ngOnInit() {}

}
