import {Component, Inject, Input, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EVService} from '../../services/ev.service';
import {UtilsService} from '../../services/utils.service';
import {environment} from '../../../environments/environment';
import {MixpanelService} from '../../services/mixpanel.service';
import {LoaderService} from '../../services/loader.service';
import {IProjet} from '../../interfaces/IProjet';
import {ITab} from '../../interfaces/ITab';

@Component({
    selector: 'app-detail-sujet',
    templateUrl: './detail-sujet.page.html',
    styleUrls: ['./detail-sujet.page.scss'],
})
export class DetailSujetPage implements OnInit {
    segment;
    profile: any;
    photoUrl = environment.sujet_photo_url;
    sujet;
    edition = false;
    hasUpdated = false;
    duree = 20;
    subsegment = 'rem';
    params: { active: boolean; url: string; };
    isLoading = true;
    projet: IProjet;

    urbadata: any;
    paramsHeader = {};
    imgURL: string | ArrayBuffer;
    profileJson: any;
    id_projet: any;
    id_sujet: any;
    id_secteur: any;

    constructor(
        public utils: UtilsService,
        private ev: EVService,
        private MixpanelService: MixpanelService,
        private loaderService: LoaderService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.id_projet = this.route.snapshot.params.id_projet;
        this.id_sujet = this.route.snapshot.params.id_sujet;
        this.id_secteur = this.route.snapshot.params.id_secteur;
        this.MixpanelService.track("Detail sujet", {"id_sujet": this.id_sujet});
        if (!this.id_secteur) {
            if (window.location.pathname.includes('equipement')) {
                this.paramsHeader = {'active': true, 'url': '/site/' + this.id_projet + '/equipement'};

            } else {
                this.paramsHeader = {'active': true, 'url': '/site/' + this.id_projet + '/data/vegetaux'};
            }

        } else {
            this.paramsHeader = {
                'active': true,
                'url': '/site/' + this.id_projet + '/groupe/' + this.id_secteur + '/data/vegetaux'
            };
        }
        this.getData();
    }

    getData() {
        this.ev.v6_projet_entete(this.id_projet).subscribe(res => {
            this.projet = res;
        });
        this.ev.v6_sujet_header(this.id_sujet).subscribe(res => {
            this.sujet = res;
            this.sujet.ev_duree_ouverture_display = this.sujet.ev_duree_ouverture;

            this.isLoading = false;
        });
    }
}


