import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {
    ProjetArchive, ProjetArchiveArray,
    ProjetDocument,
    ProjetDocumentArray,
} from '../interfaces/ProjetDocuments';
import {
    ProjetArchiveIndexEnum,
    ProjetDocumentIndexEnum
} from '../enums/ProjetDocumentIndexEnum';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public parseDocument(arrayDoc: ProjetDocumentArray): ProjetDocument {
        return {
            url: arrayDoc[ProjetDocumentIndexEnum.URL],
            filename: arrayDoc[ProjetDocumentIndexEnum.FILENAME],
            token: arrayDoc[ProjetDocumentIndexEnum.IMAGE_TOKEN],
        };
    }

    public parseDocuments(docs: ProjetDocumentArray[]): ProjetDocument[] {
        return docs.map(d => this.parseDocument(d));
    }

    public parseArchive(archive: ProjetArchiveArray): ProjetArchive {
        return {
            bilans: this.parseDocuments(archive[ProjetArchiveIndexEnum.BILANS]),
            bilans_op: this.parseDocuments(archive[ProjetArchiveIndexEnum.BILANS_OP]),
            autres_docs: this.parseDocuments(archive[ProjetArchiveIndexEnum.OTHERS]),
            plans_implantation: this.parseDocuments(archive[ProjetArchiveIndexEnum.PLANS_IMPLANTATION]),
            diagnostics: this.parseDocuments(archive[ProjetArchiveIndexEnum.DIAGNOSTICS]),
        };
    }

    public getFileExtension(filename: string): string {
        return filename.split('.').pop();
    }

    public getPathToPhoto(url: string, token: string): string {
        return environment.sujet_photo_url + '/Southside_api/display.php?photourl=' + url + '&image_token=' + token;
    }

    public getPathToDocument(url: string, name: string, token: string): string {
        return environment.sujet_photo_url + '/Southside_api/getDocument.php?docurl=' + url + '&namedoc=' + name + '&image_token=' + token;
    }

    downloadFile(url, name, token) {
        const filetype = this.getFileExtension(name);
        this.http.get(this.getPathToDocument(url, name, token), {
            responseType: 'blob',
            headers: {
                Accept: 'application/' + filetype,
            }
        })
            .subscribe(blob => {
                // console.log(blob);
                saveAs(blob, name);
            });
    }

}
