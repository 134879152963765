import {Component, Input, OnInit} from '@angular/core';
import {InterventionCounters, InterventionService} from '../../../services/intervention.service';
import {Observable} from 'rxjs';
import {UserRolesEnum} from '../../../enums/UserRolesEnum';

@Component({
    selector: 'app-intervention-counters',
    templateUrl: './intervention-counters.component.html',
    styleUrls: ['./intervention-counters.component.scss'],
})
export class InterventionCountersComponent {
    @Input() labels: string[] = [];
    @Input() title: string;
    @Input() role: UserRolesEnum = UserRolesEnum.INTERVENTION_ADMIN;

    counters$: Observable<InterventionCounters[]> =
        this.interventionService.counters$;

    constructor(
        private interventionService: InterventionService,
    ) {
    }
}
