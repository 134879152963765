import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {UserRolesEnum} from '../../../enums/UserRolesEnum';
import {AuthService} from '@auth0/auth0-angular';

@Component({
    selector: 'app-intervention-nav',
    templateUrl: './intervention-nav.component.html',
    styleUrls: ['./intervention-nav.component.scss'],
})
export class InterventionNavComponent implements OnInit {
    @Input() userRole: UserRolesEnum;

    constructor(
        public userService: UserService,
        public auth: AuthService,
    ) {
    }

    ngOnInit() {
    }
}
