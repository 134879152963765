import {Component, Input, OnInit} from '@angular/core';
import {ISujet} from '../../../interfaces/ISujet';

@Component({
  selector: 'app-sujet-installation-modal',
  templateUrl: './sujet-installation-modal.page.html',
  styleUrls: ['./sujet-installation-modal.page.scss'],
})
export class SujetInstallationModalPage implements OnInit {
  @Input() sujet: ISujet;
  @Input() sujetId: string | number;
  @Input() interventionId: string | number;

  constructor() { }

  ngOnInit() {
  }

}
