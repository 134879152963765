import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { DisplayService } from 'src/app/services/display.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-frise',
  templateUrl: './frise.component.html',
  styleUrls: ['./frise.component.scss'],
})
export class FriseComponent implements OnInit {
@Input() jours :any;
@Input() reverse :boolean = true;
@ViewChild(IonSlides) slides: IonSlides;
photoUrl = environment.sujet_photo_url;
startingDay: number;
slideOpts = {
  slidesPerView: 2,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 0
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
      spaceBetween: 0
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 6,
      spaceBetween: 0
    }
  }

  }
  constructor(public utils:UtilsService,public display:DisplayService) { }


  // this.slides.slideTo(this.jours.length());

  ionSlidesDidLoad(j) {
    this.slides.slideTo(j.length);
  }

  ngOnInit() {
    if (this.jours.length > 0) {
    this.startingDay = (this.jours.length / 2) - 1;
    this.jours.forEach(element => {
      if (new Date(element.date).getTime() < new Date().getTime()) {
        element.isPast = true;
        element.datedisplay = new Date(element.date).toLocaleDateString('fr-FR', {
          weekday: 'short'
        });
      }})
  }
}

}
