import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {IGalleryItem} from '../../../interfaces/IGalleryItem';

interface ImageGroup {
    groupTitle: string;
    images: IGalleryItem[];
}

@Component({
    selector: 'app-guide-installation',
    templateUrl: './guide-installation.page.html',
    styleUrls: ['./guide-installation.page.scss'],
})
export class GuideInstallationPage implements OnInit {

    imagesGroups: ImageGroup[] = [
        {
            groupTitle: 'Guide',
            images: [
                {
                    image: 'assets/schemas/ANNUELLES-3S.jpg',
                    alt: 'ANNUELLES-3S',
                    title: 'ANNUELLES-3S',
                },
                {
                    image: 'assets/schemas/ARBRE-3S.jpg',
                    alt: 'ARBRE-3S',
                    title: 'ARBRE-3S',
                },
                {
                    image: 'assets/schemas/ARBRES-6S.jpg',
                    alt: 'ARBRES-6S',
                    title: 'ARBRES-6S',
                },
                {
                    image: 'assets/schemas/ARBUSTES-VIVACES-3Spdf.jpg',
                    alt: 'ARBUSTES VIVACES 3S',
                    title: 'ARBUSTES VIVACES 3',
                },
                {
                    image: 'assets/schemas/COMPTEUR-D-EAU.jpg',
                    alt: 'COMPTEUR-D-EAU',
                    title: 'COMPTEUR-D-EAU',
                },
                {
                    image: 'assets/schemas/ELECTRO-VANNE.jpg',
                    alt: 'ELECTRO-VANNE',
                    title: 'ELECTRO-VANNE',
                },
                {
                    image: 'assets/schemas/GAZON-RUSTIQUE.jpg',
                    alt: 'GAZON-RUSTIQUE',
                    title: 'GAZON-RUSTIQUE',
                },
                {
                    image: 'assets/schemas/HAUTEUR-D-EAU.jpg',
                    alt: 'HAUTEUR-D-EAU',
                    title: 'HAUTEUR-D-EAU',
                },
                {
                    image: 'assets/schemas/MIXTE-ARBRES-VIVACES-6S.jpg',
                    alt: 'MIXTE-ARBRES-VIVACES-6S',
                    title: 'MIXTE-ARBRES-VIVACES-6S',
                },
                {
                    image: 'assets/schemas/MUR-VEGETAL.jpg',
                    alt: 'MUR-VEGETAL',
                    title: 'MUR-VEGETAL',
                },
                {
                    image: 'assets/schemas/TERRAIN-DE-SPORT.jpg',
                    alt: 'TERRAIN-DE-SPORT',
                    title: 'TERRAIN-DE-SPORT',
                }
            ],
        },
    ];

    constructor(
        private modalCtrl: ModalController,
    ) {
    }

    ngOnInit() {
    }

    closeModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

}
