import { DisplayService } from './../services/display.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DetailSujetPage } from '../pages/detail-sujet/detail-sujet.page';
import { UtilsService } from '../services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-electrovanne',
  templateUrl: './electrovanne.component.html',
  styleUrls: ['./electrovanne.component.scss'],
})
export class ElectrovanneComponent implements OnInit {
  strDays= "";
  days = [
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
    'dimanche'
];
 @Input() programmation: any; // decorate the property with @Input()
  id_projet: any;
  @Output() evenement = new EventEmitter<string>();
  constructor(private router:Router,
    private route:ActivatedRoute,
    public utils : UtilsService, private modalController:ModalController,public display:DisplayService) {

      this.id_projet = this.route.snapshot.params.id_projet;
     }




  ngOnInit() {


    if (this.programmation.Aactiver) {
    }

    if (this.programmation.lastActivatedProgram) {
      if (this.programmation.lastActivatedProgram.programme) {
        if (this.programmation.lastActivatedProgram.programme.contenu) {
          this.programmation.lastActivatedProgram.programme.contenu.frequence.forEach(element => {
            this.strDays+= this.days[element -1] + ' ';
          });
        }
      }
    }


  }

  ClickReprogrammer(){
    this.evenement.emit('reprogrammer');
  }
  ClickChangerHeure() {
    this.evenement.emit('changerheure');
  }

  goSujet(vegetalid) {

    this.router.navigate(['/site/'+this.id_projet+'/projet-watering/programmation-gen/sujet',vegetalid]);
  }


  public async presentModalSujet(programmation) {

    const modal = await this.modalController.create({
      component: DetailSujetPage,
      cssClass: 'my-custom-class',
      componentProps: {
        'id_sujet': programmation
      }
    });
    modal.onDidDismiss().then(data => {
      console.log('dismisseddataajoutvegetral', data);
      if (data.data.update) {
        if (data.data.update == true) {


        }
      }
  });
    return await modal.present();
  }

}
