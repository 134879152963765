import {Component, Input, OnInit} from '@angular/core';
import {BulletinRisqueEnum} from '../../../enums/BulletinRisqueEnum';

@Component({
  selector: 'app-risk-badge',
  templateUrl: './risk-badge.component.html',
  styleUrls: ['./risk-badge.component.scss'],
})
export class RiskBadgeComponent implements OnInit {
  @Input() risk: BulletinRisqueEnum;

  constructor() { }

  ngOnInit() {}

  deduceColor() {
    const palette = {
      [BulletinRisqueEnum.OK]: '#55c277',
      [BulletinRisqueEnum.VIGILANCE]: '#fab41d',
      [BulletinRisqueEnum.SATURE]: '#fa55cc',
      [BulletinRisqueEnum.ALERTE]: '#d54927',
      [BulletinRisqueEnum.CRISE]: '#54384E',
    };

    return palette[this.risk];
  }

}
