import {Injectable} from '@angular/core';
import {ISujetPhoto, ISujetPhotoForList} from '../interfaces/ISujetPhoto';
import {IUser} from '../interfaces/IUser';
import {environment} from '../../environments/environment';
import {IGalleryItem} from '../interfaces/IGalleryItem';
import {ISujet} from '../interfaces/ISujet';
import {DocumentService} from './document.service';

@Injectable({
    providedIn: 'root'
})
export class SujetService {

    constructor(
        private documentService: DocumentService,
    ) {
    }

    /**
     * Parse a Sujet Photo into a SujetPhoto for PhotoList Component
     */
    public parsePhotoToPhotoListItem(image: ISujetPhoto, user: IUser): ISujetPhotoForList {
        return {
            id: new Date().getUTCMilliseconds(),
            url: image.photo_path,
            user: image?.user,
            nom_sujet: image?.nom_sujet,
            blurhash: image.blurhash,
            date: image.date_photo,
            image: this.documentService.getPathToPhoto(
                image.photo_path,
                user['http://urbasense/image_token']
            ),
            title: image.photo_path.split('/').slice(5).join(),
            havetoupload: false,
            hasRotate: ''
        };
    }

    public parseSujetToGallery(sujet: ISujet, user: IUser): IGalleryItem[] {
        if (sujet.photos == null) {
            return [];
        }

        return sujet.photos.map(p => {
            return {
                image: this.documentService.getPathToPhoto(p.photo_path, user['http://urbasense/image_token']),
                alt: `${sujet.id_suj} - ${p.date_photo}`,
                title: `${sujet.id_suj} - ${p.date_photo}`
            };
        });
    }

}
