import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-intervention-remarque',
    templateUrl: './intervention-remarque.component.html',
    styleUrls: ['./intervention-remarque.component.scss'],
})
export class InterventionRemarqueComponent implements OnInit {
    @Input() remarque: string;
    summary: object;
    dataKeys = [
        // 'interv_id',
        // 'localisation_map',
        // 'localisation_confirm',
        // 'projectId',
        // 'vegetalId',
        // 'device_id',
        'presence',
        'statusConnectors',
        'identifyNewMS',
        'bifurcation',
        'rebootProcess',
        'changeBatteryProcess',
        'repareProcess',
        'isWorking',
        'installedCount_sensors',
        'installedCount_batteries',
        'installedCount_ms',
        'installedCount_regards',
        'installedCount_bouchons',
        'takenCount_sensors',
        'takenCount_batteries',
        'takenCount_ms',
        'takenCount_regards',
        'takenCount_bouchons',
    ];

    constructor() {
    }

    ngOnInit() {
        try {
            this.summary = JSON.parse(this.remarque);
        } catch (err) {
        }
    }

    canShow(key: string) {
        const value = this.summary[key];
        if (value) {
            if (Array.isArray(value) && value.length > 0) {
                return true;
            }
            return typeof value !== 'object';
        }
        return false;
    }

    dataToTranslationKey(data: unknown): string {
        if (Array.isArray(data)) {
            return data[data.length - 1];
        }
        return data as string;
    }

}

// {
// MAINTENANCE
//     "interv_id": "3533",
//     "localisation_map": {
//     "lat": 45.78512285554682,
//         "lng": 4.88119125366211,
//         "zoom": 18
//      },
//     "localisation_confirm": "yes",
//     "presence": "present",
//     "statusConnectors": "yes",
//     "identifyNewMS": "",
//     "bifurcation": "ras",
//     "rebootProcess": [],
//     "changeBatteryProcess": [],
//     "repareProcess": [],
//     "isWorking": "yes",

//     "installedCount_sensors": 0,
//     "installedCount_batteries": 0,
//     "installedCount_ms": 0,
//     "installedCount_regards": 0,
//     "installedCount_bouchons": 0,
//     "takenCount_sensors": 0,
//     "takenCount_batteries": 0,
//     "takenCount_ms": 0,
//     "takenCount_regards": 0,
//     "takenCount_bouchons": 0
// }
