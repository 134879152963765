import {Injectable} from '@angular/core';
import {InterventionStatusEnum} from '../enums/InterventionStatusEnum';
import {InterventionTypeEnum, TrueInterventionTypeEnum} from '../enums/InterventionTypeEnum';
import {
    IIntervention,
    IInterventionDetail,
    IInterventionStatus,
    InterventionHistoriqueEntry,
} from '../interfaces/IIntervention';
import {IonicColorKeyEnum} from '../enums/IonicColorKeyEnum';
import {IInterventionHeader} from '../interfaces/IInterventionHeader';
import {UserRolesEnum} from '../enums/UserRolesEnum';
import {catchError, map} from 'rxjs/operators';
import {EVService} from './ev.service';
import {UtilsService} from './utils.service';
import {EventService} from './event.service';
import {Subscription, zip, BehaviorSubject} from 'rxjs';
import {IMission} from '../interfaces/IMission';
import {AuthService} from '@auth0/auth0-angular';
import {IUser} from '../interfaces/IUser';
import {UserService} from './user.service';

export type InterventionCounters = {
    // Global
    missionsCount: number,
    interventionsCount: number,
    // Detail
    interventionDemandeesCount: number,
    interventionRunningCount: number,
    interventionWaitingForApprovalCount: number,
    interventionVerifiedCount: number,
    // Last Week
    lastWeekMaintenances: number,
    lastWeekInstallations: number,
    lastWeekRemovals: number,
    // Install by client
    installsRunningUS: number,
    installsRunningOthers: number,
    // Open count
    removalsCount: number,
    maintenancesCount: number,
    installationsCount: number,
    urgentsCount: number,
};

@Injectable({
    providedIn: 'root'
})
export class InterventionService {
    status: typeof InterventionStatusEnum = InterventionStatusEnum;
    types: typeof InterventionTypeEnum = InterventionTypeEnum;

    // Counters States
    private countersBehaviorSubject = new BehaviorSubject<InterventionCounters[]>(null);
    counters$ = this.countersBehaviorSubject.asObservable();
    counters: InterventionCounters[] = [];
    hasInitCounters = false;


    private countersSubscription: Subscription;
    user: IUser;

    constructor(
        private ev: EVService,
        private utils: UtilsService,
        private eventService: EventService,
        private auth: AuthService,
        private userService: UserService,
    ) {
        this.auth.user$.subscribe(u => {
            this.user = u as IUser;
            this.initCounters();
        });

        this.eventService
            .interventionsAndMissions$
            .subscribe((manuallyTriggered) => {
                if (manuallyTriggered) {
                    this.setCounters();
                }
            });
    }


    /*------------Status Management ------------------*/
    /**
     * Returns an array of every possible Status
     */
    getAllStatus(): InterventionStatusEnum[] {
        return Object.values(InterventionStatusEnum);
    }

    /**
     * Returns an array of every possible Status
     */
    getAllTypes(): TrueInterventionTypeEnum[] {
        return Object.values(TrueInterventionTypeEnum);
    }

    /**
     * Tells if the given status refers to a finished Intervention
     * (either cancelled or completed and verified).
     */
    isFinished(status: InterventionStatusEnum): boolean {
        return [
            InterventionStatusEnum.ANNULEE,
            InterventionStatusEnum.CONFORME,
        ].includes(status);
    }

    /**
     * Tells if the given status refers to a finished Intervention
     * (either non-conforme or completed and verified).
     */
    isFinishedNotCancelled(status: InterventionStatusEnum): boolean {
        return [
            InterventionStatusEnum.NON_CONFORME,
            InterventionStatusEnum.CONFORME,
        ].includes(status);
    }

    /**
     * Get ionic icon depending on given Intervention Status
     * Icons list: https://ionic.io/ionicons
     */
    getStatusIcon(status: InterventionStatusEnum): string {
        const icons = {
            [InterventionStatusEnum.UNKNOWN]: 'help-outline',
            [InterventionStatusEnum.DEMANDEE]: 'alert-outline',
            [InterventionStatusEnum.ANNULEE]: 'trash-outline',
            [InterventionStatusEnum.AFFECTEE]: 'unlink-outline',
            [InterventionStatusEnum.PROPOSEE]: 'mail-outline',
            [InterventionStatusEnum.ACCEPTEE]: 'hand-left-outline',
            [InterventionStatusEnum.REFUSEE]: 'unlink-outline',
            [InterventionStatusEnum.REALISEE]: 'checkmark-outline',
            [InterventionStatusEnum.NON_REALISEE]: 'hammer-outline',
            [InterventionStatusEnum.CONFORME]: 'checkmark-circle-outline',
            [InterventionStatusEnum.NON_CONFORME]: 'sad-outline',
            [InterventionStatusEnum.EN_COURS]: 'globe-outline',
        };
        return icons[status];
    }

    /**
     * Get ionic color depending on given Intervention Status
     * @param statuS
     * Ionic colors: https://ionicframework.com/docs/theming/basics
     */
    getStatusColor(status: InterventionStatusEnum): string {
        const colors = {
            [InterventionStatusEnum.UNKNOWN]: IonicColorKeyEnum.LIGHT,
            [InterventionStatusEnum.DEMANDEE]: IonicColorKeyEnum.WARNING,
            [InterventionStatusEnum.ANNULEE]: IonicColorKeyEnum.DANGER,
            [InterventionStatusEnum.AFFECTEE]: IonicColorKeyEnum.TERTIARY,
            [InterventionStatusEnum.PROPOSEE]: IonicColorKeyEnum.SECONDARY,
            [InterventionStatusEnum.ACCEPTEE]: IonicColorKeyEnum.PRIMARY,
            [InterventionStatusEnum.REFUSEE]: IonicColorKeyEnum.TERTIARY,
            [InterventionStatusEnum.REALISEE]: IonicColorKeyEnum.SUCCESS,
            [InterventionStatusEnum.NON_REALISEE]: IonicColorKeyEnum.WARNING,
            [InterventionStatusEnum.CONFORME]: IonicColorKeyEnum.SUCCESS,
            [InterventionStatusEnum.NON_CONFORME]: IonicColorKeyEnum.DANGER,
            [InterventionStatusEnum.EN_COURS]: IonicColorKeyEnum.TERTIARY,
        };
        return colors[status];
    }

    /**
     * Returns an Object with key being each Status, and value a boolean.
     * Example : {
     *     STATUS_A: false
     *     STATUS_B: false
     * }
     */
    getStatusAsSwitches(initialValue: boolean = true): { [key: string]: boolean } {
        const switches = {};
        this.getAllStatus().forEach(status => {
            switches[status] = initialValue;
        });

        return switches;
    }

    getStatusAsSwitchesForInstallateur(): { [key: string]: boolean } {
        const switches = {};
        this.getAllStatus().forEach(status => {
            switches[status] = this.isShowableToInstallateur(status);
        });

        return switches;
    }

    exportExcel(type: string){
        this.ev.exportExcel(type).subscribe((data:any) => {
            var blob = new Blob([data.body], {type: data.body.type});
            saveAs(blob,type + '.xlsx');
        });
    }

    getTypesAsSwitches(initialValue: boolean = true): { [key: string]: boolean } {
        const switches = {};
        this.getAllTypes().forEach(status => {
            switches[status] = initialValue;
        });

        return switches;
    }

    getWaitingForApprovalStatus(): InterventionStatusEnum[] {
        return [
            InterventionStatusEnum.REALISEE,
            InterventionStatusEnum.NON_REALISEE,
        ];
    }

    /**
     * Returns only Status "Interventions à Planifier"
     */
    getToBePlanifiedStatus(): InterventionStatusEnum[] {
        return [
            InterventionStatusEnum.DEMANDEE,
            InterventionStatusEnum.PROPOSEE
        ];
    }

    getAllStatusExceptConformeAndDone(): InterventionStatusEnum[] {
        const allStatus = Object.values(InterventionStatusEnum);
        const except = [
            InterventionStatusEnum.UNKNOWN,
            InterventionStatusEnum.CONFORME,
        ];
        return allStatus.filter(status => !except.includes(status));
    }

    /**
     * Returns only Status "Interventions en cours"
     */
    getStillRunningStatus(): InterventionStatusEnum[] {
        return [
            InterventionStatusEnum.AFFECTEE,
            InterventionStatusEnum.ACCEPTEE,
            InterventionStatusEnum.PROPOSEE,
            InterventionStatusEnum.EN_COURS,
            InterventionStatusEnum.DEMANDEE,
        ];
    }

    getFinishedStatus(): InterventionStatusEnum[] {
        return [
            InterventionStatusEnum.NON_CONFORME,
            InterventionStatusEnum.CONFORME,
        ];
    }

    // Calcule si on peut montrer le bouton supprimer intervention
    isShowable(status: InterventionStatusEnum): boolean {
        return [
            InterventionStatusEnum.AFFECTEE,
            InterventionStatusEnum.PROPOSEE,
            InterventionStatusEnum.ACCEPTEE,
            InterventionStatusEnum.REALISEE,
            InterventionStatusEnum.NON_REALISEE,
            InterventionStatusEnum.CONFORME
        ].includes(status);
    }

    isShowableToInstallateur(status: InterventionStatusEnum): boolean {
        return [
            InterventionStatusEnum.AFFECTEE,
            InterventionStatusEnum.ACCEPTEE,
        ].includes(status);
    }

    isOpenOrRefused(status: InterventionStatusEnum): boolean {
        return [
            InterventionStatusEnum.DEMANDEE,
            InterventionStatusEnum.REFUSEE,
        ].includes(status);
    }

    isDeletableByAdmin(status: InterventionStatusEnum): boolean {
        return [
            InterventionStatusEnum.DEMANDEE,
            InterventionStatusEnum.REFUSEE,
            InterventionStatusEnum.ACCEPTEE,
            InterventionStatusEnum.AFFECTEE,
            InterventionStatusEnum.PROPOSEE,
        ].includes(status);
    }

    /*------------Type Management ------------------*/
    /**
     * Tells if type relates to Maintenance
     */
    isMaintenanceType(type: InterventionTypeEnum): boolean {
        return [
            InterventionTypeEnum.MAINTENANCE,
            InterventionTypeEnum.MAINTENANCE_OLD
        ].includes(type);
    }

    /**
     * Parses Intervention Type to "true" intervention Type
     * Handles 'maint'/'maintenance' case and "depose' types
     */
    getTrueType(intervention: IIntervention | IInterventionHeader): TrueInterventionTypeEnum {
        let details: IInterventionDetail;
        try {
            details = JSON.parse(intervention.interv_detail);
            // DEPOSE type needs to be deduces as it doesn't clearly appear in DB
            if (this.isMaintenanceType(intervention.interv_type)
                && details.a_deposer) {
                return TrueInterventionTypeEnum.DEPOSE;
            }
        } catch (err) {
            // Can't parse
        }

        // We need to handle 'maint' and 'maintenance' cases
        const trueTypes = {
            [InterventionTypeEnum.MAINTENANCE]: TrueInterventionTypeEnum.MAINTENANCE,
            [InterventionTypeEnum.MAINTENANCE_OLD]: TrueInterventionTypeEnum.MAINTENANCE,
            [InterventionTypeEnum.INSTALLATION]: TrueInterventionTypeEnum.INSTALLATION,
            [InterventionTypeEnum.DEPLACEMENT]: TrueInterventionTypeEnum.DEPLACEMENT,
        };

        return trueTypes[intervention.interv_type];
    }

    getTypeIcon(type: InterventionTypeEnum, details: IInterventionDetail = {}): string {
        const PATH_TO_ICONS = './assets/icons/intervention_type/';
        if (this.isMaintenanceType(type) && details.a_deposer) {
            return PATH_TO_ICONS + 'maint_a_deposer.svg';
        }
        const icons = {
            [InterventionTypeEnum.MAINTENANCE]: 'maint.svg',
            [InterventionTypeEnum.MAINTENANCE_OLD]: 'maint.svg',
            [InterventionTypeEnum.INSTALLATION]: 'install.png',
            [InterventionTypeEnum.DEPLACEMENT]: 'deplacement.png',
        };
        return PATH_TO_ICONS + icons[type];
    }

    getTrueTypeIcon(type: TrueInterventionTypeEnum): string {
        const PATH_TO_ICONS = './assets/icons/intervention_type/';
        const icons = {
            [TrueInterventionTypeEnum.MAINTENANCE]: 'maint.svg',
            [TrueInterventionTypeEnum.INSTALLATION]: 'install.png',
            [TrueInterventionTypeEnum.DEPLACEMENT]: 'deplacement.png',
            [TrueInterventionTypeEnum.DEPOSE]: 'maint_a_deposer.svg',
        };
        return PATH_TO_ICONS + icons[type];
    }

    /**
     * Returns only still running Interventions
     */
    filterActiveInterventions(interventions: IIntervention[]): IIntervention[] {
        return interventions.filter(intervention => {
            if (null == intervention.status) {
                return false;
            }
            return !this.isFinished(intervention.status.intst_statut);
        });
    }

    /**
     * Returns only finished interventions
     */
    filterFinishedInterventions(interventions: IIntervention[]): IIntervention[] {
        return interventions.filter(intervention => this.isFinished(intervention.status.intst_statut));
    }

    /**
     * Returns only finished interventions
     */
    filterFinishedNotCancelledInterventions(interventions: IIntervention[]): IIntervention[] {
        return interventions.filter(intervention => this.isFinishedNotCancelled(intervention.status.intst_statut));
    }

    filterFinishedNotCancelledInterventionsHeaders(interventionsH: IInterventionHeader[]) {
        return interventionsH.filter(intervention => {
            return [
                InterventionStatusEnum.NON_CONFORME,
                InterventionStatusEnum.CONFORME,
            ].includes(intervention.intst_statut);
        });
    }

    /**
     * Checks if an Intervention is for Urbasense (code 1) or
     * another client
     */
    isUrbasenseInterventionHeader(intervention: IInterventionHeader): boolean {
        return intervention.organisation === '1';
    }

    /**
     * Sets the urgent (and "critique") props of an Intervention object
     * based on existing InterventionDetails
     * Returns only the urgent value
     * @param intervention
     */
    isUrgent(intervention: IIntervention): boolean {
        try {
            const details = JSON.parse(intervention.interv_detail);
            // If intervention isCritique, it should be considered as Urgent as well
            intervention.urgent = details?.urgent || details?.critique;
        } catch {
            // Couldn't parse
            intervention.urgent = false;
        }
        return intervention.urgent;
    }

    isCritique(intervention: IIntervention): boolean {
        try {
            const details = JSON.parse(intervention.interv_detail);
            intervention.critique = details?.critique;
        } catch {
            // Couldn't parse
            intervention.critique = false;
        }
        return intervention.critique;
    }

    /**
     * Sets the urgent (and "critique") props of an Intervention object
     * based on existing InterventionDetails
     * @param intervention
     */
    setUrgentCritique(intervention: IIntervention): IIntervention {
        try {
            const details = JSON.parse(intervention.interv_detail);
            // If intervention isCritique, it should be considered as Urgent as well
            intervention.urgent = details?.urgent || details?.critique;
            intervention.critique = details?.critique;
        } catch {
            // Couldn't parse
            intervention.urgent = false;
            intervention.critique = false;
        }
        return intervention;
    }


    getURLSearchParamsFromFilters(
        filtresStatus = {},
        filtresTypes = {},
        filtreUrgent = false,
    ): URLSearchParams {
        const searchParams = new URLSearchParams();
        for (const key in filtresStatus) {
            if (filtresStatus[key] === true) {
                searchParams.append('status[]', key);
            }
        }
        for (const key in filtresTypes) {
            if (filtresTypes[key] === true) {
                searchParams.append('types[]', key);
            }
        }
        searchParams.append('urgent', String(filtreUrgent));
        return searchParams;
    }

    // Used to set the counters on app launch
    // If counters where already initialized, this
    // method won't do anything to avoid unecessary api calls
    public initCounters() {
        if (!this.hasInitCounters) {
            this.setCounters();
        }
    }

    // Set the counters for any user roles of
    // the current user or update them.
    // On init, components should use initCounters method
    // to avoid multiple calls
    public setCounters() {
        this.hasInitCounters = true;
        const roles = [UserRolesEnum.INTERVENTION_ADMIN, UserRolesEnum.INSTALLATEUR];

        if (null != this.countersSubscription) {
            // Avoid unecessary multiple subscription
            this.countersSubscription.unsubscribe();
        }

        for (const role of roles) {
            // Only managing counters if user has role
            if (this.userService.hasRole(this.user, role)) {
                const interventionHeaders$ = this.ev.v6_projets_interventions_headers(role);
                const missionHeaders$ = this.ev.v6_projets_missions_headers(role);

                this.countersSubscription = zip(interventionHeaders$, missionHeaders$).pipe(
                    catchError((err) => {
                        console.error(err);
                        return [];
                    }),
                    map(([interventions, missions]: [IInterventionHeader[], IMission[]]) => {
                        const counters: InterventionCounters = {
                            missionsCount: missions.length,
                            interventionsCount: interventions.length,
                            interventionDemandeesCount: 0,
                            interventionRunningCount: 0,
                            interventionWaitingForApprovalCount: 0,
                            interventionVerifiedCount: 0,
                            lastWeekMaintenances: 0,
                            lastWeekInstallations: 0,
                            lastWeekRemovals: 0,
                            installsRunningUS: 0,
                            installsRunningOthers: 0,
                            urgentsCount: 0,
                            removalsCount: 0,
                            maintenancesCount: 0,
                            installationsCount: 0,
                        };

                        if (role === UserRolesEnum.INTERVENTION_ADMIN) {
                            // Approved count BIG LOOP
                            counters.interventionVerifiedCount = interventions.filter(i => {
                                return i.intst_statut === InterventionStatusEnum.CONFORME;
                            }).length;

                            // Waiting for approval count BIG LOOP
                            counters.interventionWaitingForApprovalCount = interventions.filter(i => {
                                return this.getWaitingForApprovalStatus()
                                    .includes(i.intst_statut);
                            }).length;

                            this.setCountersForList(counters, interventions);
                            this.setCountersForMenu(counters, interventions);
                        }

                        this.counters[role] = counters;
                    })
                ).subscribe((counters) => {
                    this.countersBehaviorSubject.next(this.counters);
                });
            }
        }
    }

    private setCountersForMenu(
        counters: InterventionCounters,
        interventions: IInterventionHeader[]
    ) {
        // Getting last week period
        const lastWeek = this.utils.getWeekBoundaries(1);

        // Filter Last Week interventions
        const lastWeekFinishedInterventions = interventions.filter(i => {
            const date = new Date(i.date_maj);
            return this.utils.isInDatePeriod(date, lastWeek)
                && this.isFinishedNotCancelled(i.intst_statut);
        });

        // Counting last week installations by type
        for (const i of lastWeekFinishedInterventions) {
            switch (this.getTrueType(i)) {
                case TrueInterventionTypeEnum.INSTALLATION:
                    counters.lastWeekInstallations++;
                    break;
                case TrueInterventionTypeEnum.DEPOSE:
                    counters.lastWeekRemovals++;
                    break;
                case TrueInterventionTypeEnum.MAINTENANCE:
                    counters.lastWeekMaintenances++;
                    break;
            }
        }
    }

    private setCountersForList(
        counters: InterventionCounters,
        interventions: IInterventionHeader[]
    ) {
        const runningInterventions = interventions.filter(i => {
            return this.getStillRunningStatus().includes(i.intst_statut);
        });

        // Still running count
        counters.interventionRunningCount = runningInterventions.length;

        for (const i of runningInterventions) {
            if (i.intst_statut === InterventionStatusEnum.DEMANDEE) {
                counters.interventionDemandeesCount++;
            }

            switch (this.getTrueType(i)) {
                case TrueInterventionTypeEnum.INSTALLATION:
                    counters.installationsCount++;
                    if (this.isUrbasenseInterventionHeader(i)) {
                        counters.installsRunningUS++;
                    } else {
                        counters.installsRunningOthers++;
                    }
                    break;
                case TrueInterventionTypeEnum.DEPOSE:
                    counters.removalsCount++;
                    break;
                case TrueInterventionTypeEnum.MAINTENANCE:
                    counters.maintenancesCount++;
                    break;
            }

            if (i.interv_urgente === true) {
                counters.urgentsCount++;
            }
        }
    }

    getCurrentStatus(intervention: IIntervention): InterventionHistoriqueEntry | IInterventionStatus {
        return intervention.status ?? intervention.historique[intervention.historique.length - 1] ?? null;
    }


    canMakeIntervention(intervention: IIntervention) {
        if (intervention.intst_statut != null) {
            return intervention.intst_statut === InterventionStatusEnum.ACCEPTEE;
        }
        return this.getCurrentStatus(intervention).intst_statut === InterventionStatusEnum.ACCEPTEE;
    }

    /**
     * Checks if an intervention (Installation) is awaiting a diag
     */
    needInstallationDiag(intervention: IIntervention): boolean {
        try {
            // Status is toplevel in an Intervention inside a Mission
            // but can also be in "currentStatus" on InterventionList and InterventionSingle
            const status = intervention.intst_rem ?? this.getCurrentStatus(intervention).intst_rem;
            return Boolean(JSON.parse(status).besoin_diagnostic);
        } catch (e) {
            return false;
        }
    }

    canMakeInstallationDiag(intervention: IIntervention): boolean {
        // To intervene, intervention must be open and user must be assigned to this intervention
        return this.canMakeIntervention(intervention)
            // AND the intervention must be an installation
            && this.getTrueType(intervention) === TrueInterventionTypeEnum.INSTALLATION
            // AND the installation must have been made (but not the diag)
            && this.needInstallationDiag(intervention)
            ;
    }
}
